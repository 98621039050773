import * as APIUtility from "../../Util/api";

import withRoot from "../../Containers/withRoot";
// --- Post bootstrap -----
import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
// import TextField from "../../Components/TextField/TextField";
import Button from "../../Components/Button/Button";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "../../Components/Typography/Typography";
import AppFooter from "../../Containers/views/AppFooter";
import AppAppBar from "../../Containers/views/AppAppBar";
import AppForm from "../../Containers/views/AppForm";
import { useAlert } from "react-alert";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { IconButton, InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  const alert = useAlert();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    APIUtility.API.makeAPICall(APIUtility.IS_SERVER_RUNNING);
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onKeyPress = (e) => {
    if (e.which === 13) {
      getToken();
    }
    setShowPassword(false);
  };

  const getToken = () => {
    const username = document.getElementById("username").value.toLowerCase();
    const password = document.getElementById("password").value;
    APIUtility.API.getTokenFromAPI(username, password).then((response) => {
      if (response && response.status !== 200) {
        response.json().then((result) => {
          alert.show(result.message);
        });
      }
    });
  };

  if (props.isServerDown) {
    return <Redirect to="/maintenance" />;
  }

  if (props.isAuthorized && !props.changePassword) {
    return <Redirect to="/home" />;
  }

  if (props.isAuthorized && props.changePassword) {
    return <Redirect to="/setpassword" />;
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {"Don't have an account yet? "}
            <Link href="/sign-up" align="center" underline="always">
              Sign Up here
            </Link>
          </Typography>
          <Typography variant="body2" align="center">
            Please use latest Firefox, Chrome, Safari, or Edge browsers.
          </Typography>
        </React.Fragment>

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            onKeyPress={onKeyPress}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onKeyPress={onKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button onClick={getToken} fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign In
          </Button>
        </form>

        {/* <Typography align="center">
          <Link underline="always" href="/forgot-password">
            Forgot password?
          </Link>
        </Typography> */}
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
};

// export default withRoot(SignIn);

const mapStateToProps = (state) => {
  return {
    isAuthorized: state.authentication.isAuthorized,
    isServerDown: state.authentication.isServerDown,
    changePassword: state.authentication.userChangePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuthorized: (authBool) => dispatch(actions.setIsAuthorized(authBool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(SignIn));
