import React, { useEffect, useState, useRef } from "react";
import { select, json } from "d3";
import SankeySVG from "./SankeySVG";
import { useResizeObserver } from "../../Util/util";
import { connect } from "react-redux";
import * as APIUtility from "../../Util/api";
import CountUp from "react-countup";

/**
 * Sankey diagram of study overview, built on d3-sankey
 */
const SummarySankey = (props) => {
  const svgRef = useRef();
  const svgWrapper = useRef();
  const dimensions = useResizeObserver(svgWrapper);

  useEffect(() => {
    APIUtility.API.makeAPICall(APIUtility.GET_DATA_SUMMARY_SANKEY);
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [dimensions, props.data]);

  const updateDimensions = () => {
    if (!dimensions) return;
    const svg = select(svgRef.current);
    svg.attr("width", dimensions.width).attr("height", dimensions.height);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          height: "100%",
          justifyContent: "flex-start",
          flexWrap: "nowrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexShrink: 0,
            flexBasis: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "inline",
              fontSize: 48,
              color: "black",
              fontFamily: "monospace",
              textTransform: "uppercase",
            }}
          >
            {props.data && <CountUp end={props.data.total} />}
          </span>
          <span
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: "inline",
              fontSize: 24,
              fontFamily: "monospace",
              textTransform: "uppercase",
            }}
          >
            participants and counting
          </span>
          RUBY study consists of a number of event stages, from baseline, 3-month, 1-year, 2-year, all the way to
          3-year. Each stage consists of a number of surveys and asessments. Use the sankey diagram below to explore the
          number of participants in various stages of the RUBY study.
        </div>
        <br />
        <div
          style={{
            minHeight: 0,
            display: "flex",
            flex: 1,
            flexShrink: 0,
            flexBasis: "70%",
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={svgWrapper}
        >
          <svg ref={svgRef}>
            {props.data && (
              <SankeySVG data={props.data} width={dimensions.width} height={dimensions.height} svg={svgRef.current} />
            )}
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.dataSummary.dataSummarySankey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleShowMoreCharts: () => dispatch(actions.toggleShowMorePieChartCompStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummarySankey);
