import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  isServerDown: false,
  isServerInMaintenance: false,
  isAuthorized: false,
  userRole: null,
  userReadOnly: null,
  userChangePassword: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_AUTHORIZED:
      return { ...state, isAuthorized: action.authBool };

    case actionTypes.SET_USER_ROLE:
      return { ...state, userRole: action.userRole };

    case actionTypes.SET_USER_READ_ONLY:
      return { ...state, userReadOnly: action.userReadOnly };

    case actionTypes.SET_USER_CHANGE_PASSWORD:
      return { ...state, userChangePassword: action.userChangePassword };

    case actionTypes.SET_IS_SERVER_DOWN:
      return { ...state, isServerDown: action.bool };

    case actionTypes.SET_IS_SERVER_IN_MAINTENANCE:
      return { ...state, isServerInMaintenance: action.bool };

    default:
      return state;
  }
};

export default reducer;
