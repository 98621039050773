import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  activeContent: ["dashboard"],
  siderCollapsed: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NAVIGATION_CURRENT_CONTENT:
      return { ...state, activeContent: action.activeContent };
    case actionTypes.SET_MENU_SIDER_COLLAPSED:
      return { ...state, siderCollapsed: action.isCollapsed };

    default:
      return state;
  }
};

export default reducer;
