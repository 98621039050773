import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  showAdditionalColumns: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SHOW_ADDITIONAL_COLUMNS:
      return { ...state, showAdditionalColumns: !state.showAdditionalColumns };
    case actionTypes.SET_PATIENT_COMPLETION_TABLE_DATA:
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default reducer;
