import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import {} from "@ant-design/icons";
import "./Landing.css";
import { Redirect } from "react-router";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";

const { Meta } = Card;

const Selector = (props) => {
  const [navigate, setNavigate] = useState(null);

  const navigateToData = () => {
    setNavigate("rubyData");
  };

  const navigateToPortal = () => {
    setNavigate("rubyPortal");
  };

  if (navigate === "rubyData") {
    return <Redirect to="/data-explorer" />;
  } else if (navigate === "rubyPortal") {
    return <Redirect to="/home" />;
  }

  return (
    <React.Fragment>
      <div className="data-explorer-card-wrapper">
        <Row gutter={24} align="bottom" justify="center">
          <Col span={8}>
            <a target="_blank" href="https://rubystudy.ca" rel="noopener noreferrer nofollow">
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<img alt="ruby study" src="./filter-resource_directory.png" />}
              >
                <Meta title="RUBY Study" description="About RUBY" />
              </Card>
            </a>
          </Col>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="explore data" src="./lights-fall.jpg" />}
              onClick={navigateToData}
            >
              <Meta title="RUBY Data" description="Explore RUBY data" />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="example" src="./web-dev.jpg" />}
              onClick={navigateToPortal}
            >
              <Meta title="RUBY Portal" description="RUBY Administrative Portal" />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // dataSummaryOptions: state.dataSummary.dataSummaryOptions,
    // chartData: state.dataSummary.data,
    // dataDictionary: state.dataSummary.dataDictionary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setDataSummaryOptions: (options) => dispatch(actions.setDataSummaryOptions(options)),
    // setChartData: (data) => dispatch(actions.setDataSummary(data)),
    // setDataDictionary: (data) => dispatch(actions.setDataDictionary(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Selector);
