import React, { useState } from "react";
import * as actions from "../../Store/Actions/index";
import * as APIUtility from "../../Util/api";
import { connect } from "react-redux";
import { Col, Input } from "antd";
import { useAlert, positions } from "react-alert";

const { Search } = Input;

const Controls = (props) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const searchPatient = (value) => {
    setIsLoading(true);
    APIUtility.API.makeAPICall(APIUtility.GET_PATIENT_PROFILE, "?id=" + value).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status === 200) {
        response.json().then((data) => {
          props.setPatientProfile(data);
        });
      } else {
        props.setPatientProfile(null);
        response.text().then((text) => alert.show(text, { position: positions.TOP_CENTER }));
      }
      setIsLoading(false);
    });
  };

  return (
    <Col xs={14} sm={10} md={6} lg={6} xl={6}>
      <Search placeholder="Participant id" onSearch={searchPatient} loading={isLoading} />
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.patientProfile.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPatientProfile: (data) => dispatch(actions.setPatientProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
