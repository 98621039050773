import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  dataSummaryOptions: null,
  data: null,
  dataDictionary: null,
  surveyEventTable: null,
  dataSummarySankey: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA_SUMMARY_OPTIONS:
      return { ...state, dataSummaryOptions: action.dataSummaryOptions };

    case actionTypes.SET_DATA_SUMMARY:
      return { ...state, data: action.data };

    case actionTypes.SET_DATA_DICTIONARY:
      return { ...state, dataDictionary: action.dataDictionary };

    case actionTypes.SET_EVENT_SURVEY_TABLE:
      return { ...state, surveyEventTable: action.data };
    case actionTypes.SET_DATA_SUMMARY_SANKEY:
      return { ...state, dataSummarySankey: action.data };

    default:
      return state;
  }
};

export default reducer;
