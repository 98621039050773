import React, { useState, useEffect } from "react";
import * as APIUtility from "../../Util/api";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Table, Pagination, Input, Button, Tooltip } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { makePaginationRequestParams } from "../../Util/pagination";
import { useAlert } from "react-alert";
import { urlJoinPath } from "../../Util/url";

const lodash = require("lodash");

let searchInput = React.createRef();

const PatientCompletionStatusTable = (props) => {
  const alert = useAlert();
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    pageSize: 20,
  });
  const [sorterSettings, setSorterSettings] = useState({
    order: "ascend",
    orderBy: "id",
  });
  const [filterSettings, setFilterSettings] = useState({});

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [anchorLeftColumnBool, setAnchorLeftColumnBool] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);

  useEffect(() => {
    getTableData({});
  }, []);

  const eventQueryOptionsString = urlJoinPath(props.eventType, props.eventStatus);

  const getTableData = ({ pagination = paginationSettings, sorter = sorterSettings, filters = filterSettings }) => {
    const paramString = makePaginationRequestParams(pagination, sorter, filters, eventQueryOptionsString);

    setIsLoading(true);
    APIUtility.API.makeAPICall(APIUtility.GET_PATIENT_EVENT_STATUS, paramString)
      .then((response) => response.json())
      .then((result) => {
        props.setTableData(props.parseDataFunc(result.data));
        setPaginationSettings({
          page: result.page,
          pageSize: result.per_page,
          totalPage: result.total_pages,
          total: result.total,
          next: result.next,
          previous: result.previous,
        });
        setIsLoading(false);
        setLoadingFailed(false);
      })
      .catch(() => {
        alert.show("Failed to fetch data, please try again.");
        setLoadingFailed(true);
        setIsLoading(false);
      });
  };

  const expandedRowRender = (record, index, indent, panded) => {
    let description = "Outstanding surveys: ";
    record.survey_status[props.eventType + "_outstanding_surveys"].forEach((survey, i) => {
      if (i === 0) {
        description += survey;
      } else {
        description += ", " + survey;
      }
    });
    return <p>{description}</p>;
  };

  /**
   * Called when an row is expanded
   */
  const onRowExpand = (expanded, record) => {
    console.log(props.allowExpand);
    if (props.allowExpand && expanded) {
      setExpandedRowKeys([record.patient_id]);
      setAnchorLeftColumnBool(false);
    } else {
      setExpandedRowKeys([]);
      setAnchorLeftColumnBool(true);
    }
  };

  /**
   * Called on when pagination changes page
   */
  const onPageChange = (page, pageSize) => {
    const pagination = { ...paginationSettings, page };
    getTableData({ pagination });
  };

  /**
   * Called on when pagination changes page size
   */
  const onPageSizeChange = (page, pageSize) => {
    const newMaxPage = Math.floor(paginationSettings.total / pageSize);
    let newPage = Math.floor((paginationSettings.page * paginationSettings.pageSize) / pageSize);

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > newMaxPage) {
      newPage = newMaxPage;
    }

    const pagination = { pageSize, page: newPage };
    setPaginationSettings(pagination);

    getTableData({ pagination });
  };

  /**
   * Returns the element to be displayed to the left of the pagination
   */
  const showPaginationTotal = (total, range) => {
    const [start, end] = range;
    return start + " - " + end + " / " + total;
  };

  /**
   * Called on when table is being changed.
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    const orderBy = sorter.columnKey;
    const order = sorter.order;
    const sorterSettings = { order, orderBy };

    setSorterSettings(sorterSettings);
    setFilterSettings(filters);

    getTableData({ sorter: sorterSettings, filters });
  };

  /**
   * Table column search prop, used by the column header list definition
   */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  /**
   * Removes some data table columns based on event type
   */
  const getDataColumns = () => {
    const columns = props.makeMainDataColumnsFunc(
      anchorLeftColumnBool,
      getColumnSearchProps,
      props.showAdditionalColumns
    );
    if (props.eventStatus === "not_started") {
      const filteredColumns = columns.filter(
        (col) => col.key !== "num_outstanding_surveys" && col.key !== "ffq_complete"
      );
      return filteredColumns;
    } else if (props.eventStatus === "completed") {
      const filteredColumns = columns.filter(
        (col) =>
          col.key !== "num_outstanding_surveys" && col.key !== "pass_reminder_window" && col.key !== "ffq_complete"
      );
      return filteredColumns;
    } else if (props.eventStatus === "withdrawn") {
      const filteredColumns = columns.filter(
        (col) =>
          col.key !== "num_outstanding_surveys" &&
          col.key !== "pass_reminder_window" &&
          col.key !== "ruby_group" &&
          col.key !== "brocap_consent_date"
      );
      return filteredColumns;
    }

    return columns;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**
   * Reset button in column header search pop-up
   */
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  if (loadingFailed) {
    return (
      <Tooltip title="reload">
        <Button
          type="secondary"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => {
            getTableData({});
          }}
          loading={isLoading}
        />
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <Table
        rowKey={(record) => record.patient_id}
        columns={getDataColumns()}
        expandedRowRender={expandedRowRender}
        expandedRowKeys={expandedRowKeys}
        onExpand={onRowExpand}
        dataSource={props.tableData}
        pagination={false}
        scroll={{ x: "max-content", y: "75vh" }}
        onChange={onTableChange}
        bordered
        size="small"
        loading={isLoading}
      />
      <Pagination
        current={paginationSettings.page}
        total={paginationSettings.total}
        defaultPageSize={paginationSettings.pageSize}
        pageSizeOptions={["10", "20", "50", "100", "200"]}
        onChange={onPageChange}
        onShowSizeChange={onPageSizeChange}
        showTotal={showPaginationTotal}
        showSizeChanger
        showQuickJumper
        style={{ margin: 5 }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showAdditionalColumns: state.patientCompletionStatusTable.showAdditionalColumns,
    tableData: state.patientCompletionStatusTable.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTableData: (data) => dispatch(actions.setPatientCompletionTableData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientCompletionStatusTable);
