import React, { useState, useEffect } from "react";
import * as APIUtility from "../../Util/api";
// import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Transfer, Switch, Table, Tag, Menu, Dropdown } from "antd";
import difference from "lodash/difference";
import moment from "moment";
import { PlusOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import "./ActivationManagement.css";
import { useAlert } from "react-alert";

const lodash = require("lodash");

const AdminActivateUsers = (props) => {
  const alert = useAlert();
  const [userListData, setUserListData] = useState([]);
  const [activeListKeys, setActiveListKeys] = useState([]);
  const [editDisabled, setEditDisabled] = useState(true);

  useEffect(() => {
    getUserListData();
  }, []);

  // Customize Table Transfer
  const TableTransfer = ({ leftColumns, rightColumns, leftTitle, rightTitle, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;
        const title = direction === "left" ? leftTitle : rightTitle;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            title={() => title}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

  const dateToString = (datetime) => {
    const date = moment(datetime);
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  const rightUserTableColumns = [
    {
      dataIndex: "username",
      title: "Username",
    },
    {
      dataIndex: "last_login",
      title: "Last Login",
      render: dateToString,
    },
  ];

  const leftUserTableColumns = [
    {
      dataIndex: "username",
      title: "Username",
    },
    {
      dataIndex: "email",
      title: "Email",
    },
  ];

  const getUserListData = () => {
    APIUtility.API.makeAPICall(APIUtility.GET_USER_LIST).then((response) => processApiData(response));
  };

  const processApiData = (response) => {
    return new Promise(() => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status == 200) {
        response.json().then((result) => {
          const data = result.map((item) => {
            if (item.role === "admin") {
              item.disabled = true;
            }
          });
          setUserListData(result);
          const activatedKeys = result.filter((item) => item.is_active === true).map((item) => item.id);
          setActiveListKeys(activatedKeys);
        });
      } else {
        alert.show("Failed to fetch data.");
      }
    });
  };

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    // setActiveListKeys(nextTargetKeys);
    // console.log("targetKeys: ", nextTargetKeys);
    // console.log("direction: ", direction);
    // console.log("moveKeys: ", moveKeys);
    const activationActions = {};

    if (direction === "left") {
      //deactivate users
      activationActions["deactivate"] = moveKeys;
    } else if (direction === "right") {
      //activate users
      activationActions["activate"] = moveKeys;
    }

    APIUtility.API.makeAPICall(APIUtility.ADMIN_ACTIVATE_DEACTIVATE_USERS, null, activationActions).then((response) =>
      processApiData(response)
    );
  };

  return (
    <React.Fragment>
      <p>User Activations</p>
      <TableTransfer
        rowKey={(record) => record.id}
        dataSource={userListData}
        targetKeys={activeListKeys}
        disabled={editDisabled}
        onChange={handleChange}
        filterOption={(inputValue, item) =>
          item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
        }
        leftTitle={"Users Awaiting Activation"}
        leftColumns={leftUserTableColumns}
        rightTitle={"Activated Users"}
        rightColumns={rightUserTableColumns}
      />
      <Switch
        unCheckedChildren={<UnlockOutlined />}
        checkedChildren={<LockOutlined />}
        checked={editDisabled}
        onChange={setEditDisabled}
        style={{ marginTop: 16 }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    siteOptions: state.studyCompletionStatus.siteOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminActivateUsers);
