import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
// import CompletionPieChart from "./CompletionPieChart";
import EventPieChart from "./EventPieChart";

import * as APIUtility from "../../Util/api";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { useAlert } from "react-alert";
import { PIE_DATA_DEFAULT, PARSE_SET_CHART_DATA, PIE_SIZES } from "./PieConstants";

import PieChartLegend from "./PieChartLegend";

const StudyCompletionStatus = (props) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initializePieData();
  }, []);

  const getChartData = () => {
    setIsLoading(true);
    APIUtility.API.makeAPICall(APIUtility.SURVEY_COMPLETION_STATUS).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status !== 200) {
        response.text().then((text) => alert.show(text));
      } else {
        response.json().then((data) => {
          PARSE_SET_CHART_DATA(data);
          props.setSiteOptions(data.site_options);

          const siteOptionValues = Object.entries(data.site_options).map(([key, value]) => key);
          props.setSelected(siteOptionValues);
        });
      }
      setIsLoading(false);
    });
  };

  const initializePieData = () => {
    if (props.data === null) {
      getChartData();
    }
  };

  const setPieDataByKey = (key, newData) => {
    props.setData({ ...props.data, [key]: newData });
  };

  const renderMonth3PieChart = () => {
    if (props.showMorePieCharts) {
      return (
        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Month 3"}
            data={props.data ? props.data.threemonth : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("threemonth", data)}
            queryKey={"threemonth"}
          />
        </Col>
      );
    }
  };

  const renderPostTreatmentPieChart = () => {
    if (props.showMorePieCharts) {
      return (
        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Post Treatment"}
            data={props.data ? props.data.posttreatment : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("posttreatment", data)}
            queryKey={"posttreatment"}
          />
        </Col>
      );
    }
  };

  const renderYear2PieChart = () => {
    if (props.showMorePieCharts) {
      return (
        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Year 2"}
            data={props.data ? props.data.twoyear : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("twoyear", data)}
            queryKey={"twoyear"}
          />
        </Col>
      );
    }
  };

  const getPieColSizes = () => {
    return {
      xs: PIE_SIZES[props.pieSize].xs,
      sm: PIE_SIZES[props.pieSize].sm,
      md: PIE_SIZES[props.pieSize].md,
      lg: PIE_SIZES[props.pieSize].lg,
      xl: PIE_SIZES[props.pieSize].xl,
    };
  };

  if (isLoading) {
    return <Spin />;
  } else if (props.data === null) {
    return (
      <Tooltip title="reload">
        <Button type="secondary" shape="circle" icon={<ReloadOutlined />} onClick={getChartData} />
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Baseline"}
            data={props.data ? props.data.baseline : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("baseline", data)}
            queryKey={"baseline"}
          />
        </Col>
        {renderMonth3PieChart()}
        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Year 1"}
            data={props.data ? props.data.oneyear : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("oneyear", data)}
            queryKey={"oneyear"}
          />
        </Col>
        {renderPostTreatmentPieChart()}
        {renderYear2PieChart()}

        <Col {...getPieColSizes()}>
          <EventPieChart
            title={"Year 3"}
            data={props.data ? props.data.threeyear : PIE_DATA_DEFAULT}
            reveal={props.data === null ? 0 : 100}
            setData={(data) => setPieDataByKey("threeyear", data)}
            queryKey={"threeyear"}
          />
        </Col>
      </Row>

      <PieChartLegend show={props.data === null ? false : true} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    pieSize: state.studyCompletionStatus.pieSize,
    showMorePieCharts: state.studyCompletionStatus.showMorePieCharts,
    data: state.studyCompletionStatus.data,
    selectedSites: state.studyCompletionStatus.selectedSites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setData: (data) => dispatch(actions.setDataCompStatus(data)),
    setSiteOptions: (options) => dispatch(actions.setSiteOptions(options)),
    setSelected: (selected) => dispatch(actions.setSiteSelected(selected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyCompletionStatus);
