import withRoot from "../../Containers/withRoot";
import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Typography from "../../Components/Typography/Typography";
import AppFooter from "../../Containers/views/AppFooter";
import AppAppBar from "../../Containers/views/AppAppBar";
import AppForm from "../../Containers/views/AppForm";

import { Redirect } from "react-router";

const Maintenance = (props) => {
  const [timer, setTimer] = useState(60);

  const id = React.useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  if (timer === 0) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <Typography variant="h6" marked="center" align="center">
          We will be right back!
        </Typography>
        <br />
        <Typography variant="body2" align="center">
          The RUBY server is currently down for maintenance.
        </Typography>

        <br />
        <Typography variant="body2" align="center">
          {"Returning to "}
          <Link href="/" align="center" underline="always">
            home
          </Link>
          {" in " + timer + " seconds..."}
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(Maintenance);
