export const SET_IS_AUTHORIZED = "SET_IS_AUTHORIZED";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_READ_ONLY = "SET_USER_READ_ONLY";
export const SET_USER_CHANGE_PASSWORD = "SET_USER_CHANGE_PASSWORD";
export const SET_IS_SERVER_DOWN = "SET_IS_SERVER_DOWN";
export const SET_IS_SERVER_IN_MAINTENANCE = "SET_IS_SERVER_IN_MAINTENANCE";

export const SET_NAVIGATION_CURRENT_CONTENT = "SET_NAVIGATION_CURRENT_CONTENT";
export const SET_MENU_SIDER_COLLAPSED = "SET_MENU_SIDER_COLLAPSED";

export const SET_PIE_CHART_SITE_OPTIONS = "SET_PIE_CHART_SITE_OPTIONS";
export const SET_PIE_CHART_SITES_SELECTED = "SET_PIE_CHART_SITES_SELECTED";
export const INCREASE_PIE_CHART_SIZE_COMP_STATUS = "INCREASE_PIE_CHART_SIZE_COMP_STATUS";
export const REDUCE_PIE_CHART_SIZE_COMP_STATUS = "REDUCE_PIE_CHART_SIZE_COMP_STATUS";
export const TOGGLE_SHOW_MORE_PIE_CHART_COMP_STATUS = "TOGGLE_SHOW_MORE_PIE_CHART_COMP_STATUS";
export const SET_DATA_COMP_STATUS = "SET_DATA_COMP_STATUS";

export const TOGGLE_SHOW_ADDITIONAL_COLUMNS = "TOGGLE_SHOW_ADDITIONAL_COLUMNS";
export const SET_PATIENT_COMPLETION_TABLE_DATA = "SET_PATIENT_COMPLETION_TABLE_DATA";

export const SET_PATIENTS_LIST_TABLE_DATA = "SET_PATIENTS_LIST_TABLE_DATA";

export const SET_PATIENT_PROFILE = "SET_PATIENT_PROFILE";

export const SET_DATA_SUMMARY_OPTIONS = "SET_DATA_SUMMARY_OPTIONS";
export const SET_DATA_SUMMARY = "SET_DATA_SUMMARY";
export const SET_DATA_DICTIONARY = "SET_DATA_DICTIONARY";
export const SET_EVENT_SURVEY_TABLE = "SET_EVENT_SURVEY_TABLE";
export const SET_DATA_SUMMARY_SANKEY = "SET_DATA_SUMMARY_SANKEY";

export const SET_DATA_CARE_PATHWAY = "SET_DATA_CARE_PATHWAY";
export const SET_ACTIVE_CHAPTER_CARE_PATHWAY = "SET_ACTIVE_CHAPTER_CARE_PATHWAY";
export const SET_AGE_RANGE_CARE_PATHWAY = "SET_AGE_RANGE_CARE_PATHWAY";
export const SET_AGE_RANGE_DROPDOWN_OPEN_CARE_PATHWAY = "SET_AGE_RANGE_DROPDOWN_OPEN_CARE_PATHWAY";
