import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import React from "react";
import store from "./Store/configureStore";

import SignInPage from "./Pages/Login/SignIn";
import SignUpPage from "./Pages/Login/SignUp";
import SignUpSuccess from "./Pages/Login/SignUpSuccess";
import ChangeTemporaryPassword from "./Pages/Login/ChangeTemporaryPassword";
import Home from "./Pages/Home/Home";
import Landing from "./Pages/Landing/Landing";
import Maintenance from "./Pages/Login/Maintenance";
import DataExplorer from "./Pages/DataExplorer/DataExplorer";

const alertOptions = {
  position: positions.MIDDLE,
  timeout: 3000,
  // offset: "30px",
  transition: transitions.FADE,
};

const router = (
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/data-explorer" component={DataExplorer} />
          <Route exact path="/sign-in" component={SignInPage} />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/sign-up-success" component={SignUpSuccess} />
          <Route exact path="/setpassword" component={ChangeTemporaryPassword} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route path="/home" component={Home} />
        </Switch>
      </BrowserRouter>
    </AlertProvider>
  </Provider>
);

export default router;
