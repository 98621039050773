import React from "react";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Button, Radio, Row, Col, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Controls = (props) => {
  const renderShowColumnsButton = () => {
    if (props.showAdditionalColumns) {
      return "Hide details";
    } else {
      return "Show details";
    }
  };

  return (
    <React.Fragment>
      <Col xs={18} sm={12} md={12} lg={10} xl={10}>
        <Text>{props.title}</Text>
      </Col>
      {props.showDetail && (
        <Col xs={0} sm={7} md={5} lg={5} xl={5}>
          <Radio.Group>
            <Radio.Button onClick={props.toggleShowAdditionalColumns}>{renderShowColumnsButton()}</Radio.Button>
          </Radio.Group>
        </Col>
      )}
      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
        <Button onClick={props.download} loading={props.loading}>
          <DownloadOutlined />
        </Button>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showAdditionalColumns: state.patientCompletionStatusTable.showAdditionalColumns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleShowAdditionalColumns: () => dispatch(actions.toggleShowAdditionalColumns()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
