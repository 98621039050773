export const urlJoinPath = (...args) => {
  const cleanedPaths = [];
  args.forEach((arg) => {
    const argStr = String(arg);
    if (argStr) {
      cleanedPaths.push(argStr.replace(/^\/+/, "").replace(/\/+$/, ""));
    }
  });

  const path = cleanedPaths.join("/");

  //return an ending slash only if the path does not have optional arguments as indicated by ?
  if (path.includes("/?")) {
    return path;
  } else {
    return path + "/";
  }
};
