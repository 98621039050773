import React, { useEffect, useState } from "react";
import { Spin, Button, Tooltip, TreeSelect } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import * as APIUtility from "../../Util/api";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { useAlert } from "react-alert";

const chartTypes = [
  { label: "Bar Chart", value: "bar" },
  { label: "Line Chart", value: "line" },
];

const DataDictionary = (props) => {
  const alert = useAlert();

  const [isDataDictionaryLoading, setIsDataDictionaryLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);

  useEffect(() => {
    getDataDictionary();
  }, []);

  const getDataDictionary = () => {
    setIsDataDictionaryLoading(true);
    APIUtility.API.makeAPICall(APIUtility.GET_DATA_DICTIONARY).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status !== 200) {
        response.json().then((data) => alert.show(data.message));
      } else {
        response.json().then((data) => {
          props.setDataDictionary(data);
        });
      }
      setIsDataDictionaryLoading(false);
    });
  };

  /**
   * Called when selection changes
   */
  const onChange = (value) => {
    setSelected(value);
  };

  const filterTreeNode = (inputValue, node) => {
    const title = node.title.toLowerCase();
    return title.includes(inputValue.toLowerCase());
  };

  if (isDataDictionaryLoading | (props.dataDictionary === null)) {
    return <Spin />;
  }

  return (
    <TreeSelect
      showSearch
      style={{ width: "100%" }}
      treeData={props.dataDictionary}
      value={selected}
      searchValue={selected}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Please select"
      allowClear
      // multiple
      treeDefaultExpandAll={false}
      treeDefaultExpandedKeys={["proa", "prob"]}
      size="large"
      listHeight={500}
      onChange={onChange}
      filterTreeNode={filterTreeNode}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    dataSummaryOptions: state.dataSummary.dataSummaryOptions,
    chartData: state.dataSummary.data,
    dataDictionary: state.dataSummary.dataDictionary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDataSummaryOptions: (options) => dispatch(actions.setDataSummaryOptions(options)),
    setChartData: (data) => dispatch(actions.setDataSummary(data)),
    setDataDictionary: (data) => dispatch(actions.setDataDictionary(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataDictionary);
