import React, { useEffect } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import * as APIUtility from "../../Util/api";

import SurveyEventTable from "./SurveyEventTable";
import SummarySankey from "./SummarySankey";
import DataDictionary from "./DataDictionary";
import "antd/dist/antd.css";
import "./DataTabs.css";

const { TabPane } = Tabs;

const DataTabs = (props) => {
  return (
    <div className="card-container">
      <Tabs type="card">
        <TabPane tab="Overview" key="1">
          <SummarySankey />
        </TabPane>
        <TabPane tab="Surveys" key="2">
          <SurveyEventTable />
        </TabPane>
        <TabPane tab="Data Dictionary" key="3">
          <DataDictionary />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // table: state.dataSummary.surveyEventTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleShowMoreCharts: () => dispatch(actions.toggleShowMorePieChartCompStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTabs);
