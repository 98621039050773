import React, { useState } from "react";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { PIE_DATA_DEFAULT, COLORS, queryKeyLookup } from "./PieConstants";
import { Typography } from "antd";
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer } from "recharts";
import "./EventPieChart.css";
const lodash = require("lodash");
const { Title, Text } = Typography;

const RADIAN = Math.PI / 180;

const EventPieChart = (props) => {
  /**
   * charts everything given except for 'Pending' category into the pie
   */
  const chartData = props.data ? props.data.filter((obj) => obj.title !== "Pending") : PIE_DATA_DEFAULT;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let textAnchor = "middle";

    if (x > cx * 1.1) {
      textAnchor = "start";
    } else if (x < cx * 0.9) {
      textAnchor = "end";
    }

    return (
      <text x={x} y={y} fill="black" textAnchor={textAnchor} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-piechart-tooltip">
          <div className="label">{` ${props.title} ${payload[0].payload.title} : ${payload[0].value}`}</div>
        </div>
      );
    }

    return null;
  };

  /**
   * Render title above the pie chart.
   */
  const renderTitle = () => {
    if (props.reveal === 0) {
      return null;
    }

    let total = 0;
    let pending = 0;
    if (props.data) {
      props.data.forEach((element) => {
        if (element.title !== "Pending") {
          total = total + element.value;
        }
      });
      pending =
        lodash.get(
          props.data.find((obj) => obj.title === "Pending"),
          "value"
        ) || 0;
    }

    return (
      <div style={{ textAlign: "center" }}>
        <Title level={4} style={{ marginBottom: 0 }}>
          {props.title}
        </Title>
        <Text stle={{ fontSize: 9 }}>n={total}</Text>
        <br />
        <Text stle={{ fontSize: 9 }}>
          n<sub>pending</sub>={pending}
        </Text>
      </div>
    );
  };

  const onClickPie = (data, index, event) => {
    const queryEvent = props.queryKey;
    const queryStatus = data.title.toLowerCase().replace(/ /g, "_");

    props.setActiveContent([queryEvent + "|" + queryStatus]);
  };

  return (
    <React.Fragment>
      {renderTitle()}

      <PieChart width={400} height={400}>
        <Pie
          data={chartData}
          cx={200}
          cy={200}
          label={renderCustomizedLabel}
          outerRadius={80}
          dataKey="value"
          isAnimationActive={false}
          onClick={onClickPie}
        >
          {chartData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry["color"]} />;
          })}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveContent: (page) => dispatch(actions.setActiveContent(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPieChart);
