import React, { useState } from "react";
import * as APIUtility from "../../Util/api";
// import * as actions from "../../Store/Actions/index";
// import { connect } from "react-redux";
import { Form, Input, Button, Space } from "antd";
import { isValidPassword } from "../../Util/inputValidation";
import { useAlert } from "react-alert";

const lodash = require("lodash");

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ChangePassword = (props) => {
  const alert = useAlert();

  const onFinish = (values) => {
    const data = { new_password: values["new-password"], old_password: values["old-password"] };

    APIUtility.API.makeAPICall(APIUtility.CHANGE_PASSWORD, null, data).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status === 200) {
        alert.show("Password updated successfully.");
      } else {
        response.text().then((result) => {
          alert.show(result);
        });
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    alert.show("Please fill out required fields.");
  };

  const passwordValidator = () => ({
    validator(rule, value) {
      if (value && isValidPassword(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        "Password must be at least 8 characters long, containing both a upper and lower case letter, a number, and a special character such as one of the following: !@#$%^*."
      );
    },
  });

  const password2Validator = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value && getFieldValue("new-password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("Passwords don't match.");
    },
  });

  return (
    <Form {...layout} name="change-password" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label="Current Password"
        name="old-password"
        rules={[{ required: true, message: "Please enter your current password." }]}
      >
        <Input.Password visibilityToggle={false} id="old-password" />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="new-password"
        rules={[{ required: true, message: "Please enter your new password." }, passwordValidator]}
      >
        <Input.Password id="new-password" />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="new-password-confirm"
        rules={[{ required: true, message: "Please confirm your new password." }, password2Validator]}
      >
        <Input.Password id="new-password-2" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;
