import * as actionTypes from "./actionTypes";

export const setIsAuthorized = (authBool) => {
  return {
    type: actionTypes.SET_IS_AUTHORIZED,
    authBool,
  };
};

export const setUserRole = (userRole) => {
  return {
    type: actionTypes.SET_USER_ROLE,
    userRole,
  };
};

export const setUserReadOnly = (userReadOnly) => {
  return {
    type: actionTypes.SET_USER_READ_ONLY,
    userReadOnly,
  };
};

export const setUserChangePassword = (userChangePassword) => {
  return {
    type: actionTypes.SET_USER_CHANGE_PASSWORD,
    userChangePassword,
  };
};

export const setIsServerDown = (bool) => {
  return {
    type: actionTypes.SET_IS_SERVER_DOWN,
    bool,
  };
};

export const setIsServerInMaintenance = (bool) => {
  return {
    type: actionTypes.SET_IS_SERVER_IN_MAINTENANCE,
    bool,
  };
};
