import React, { useEffect, useRef } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import * as APIUtility from "../../Util/api";
import { useResizeObserver } from "../../Util/util";

/**
 * A table outlining surveys in study events, by group A & B
 */
const SurveyEventTable = (props) => {
  const tableWrapper = useRef();
  const dimensions = useResizeObserver(tableWrapper);

  useEffect(() => {
    APIUtility.API.makeAPICall(APIUtility.GET_SURVEY_EVENT_TABLE);
  }, []);

  const columns = props.table ? props.table.columns : [];
  const tableData = props.table ? props.table.data : [];

  return (
    <React.Fragment>
      <div style={{ height: "15%" }}>
        The participants in RUBY study are divided into two different groups. The first 600 participants are group A,
        and the latter participants are group B. This table outlines the surveys completed by the study participants at
        different event stages depending on the group they are in.
      </div>
      <div style={{ height: "85%" }} ref={tableWrapper}>
        {dimensions && (
          <Table
            columns={columns}
            size="small"
            dataSource={tableData}
            scroll={{ x: dimensions.width, y: dimensions.height }}
            style={{ width: dimensions.width, height: dimensions.height }}
            pagination={false}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.dataSummary.surveyEventTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // toggleShowMoreCharts: () => dispatch(actions.toggleShowMorePieChartCompStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEventTable);
