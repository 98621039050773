import { combineReducers } from "redux";

import authentication from "./authentication";
import navigation from "./navigation";
import studyCompletionStatus from "./studyCompletionStatus";
import patientCompletionStatusTable from "./patientCompletionStatusTable";
import patientProfile from "./patientProfile";
import patientsListTable from "./patientsListTable";
import dataSummary from "./dataSummary";
import carePathway from "./carePathway";

export default combineReducers({
  authentication,
  navigation,
  studyCompletionStatus,
  patientCompletionStatusTable,
  patientProfile,
  patientsListTable,
  dataSummary,
  carePathway,
});
