import React, { useState, useEffect } from "react";
import * as APIUtility from "../../Util/api";
import { Spin, Layout, Row, Col, Button } from "antd";
import { CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";
import { useAlert } from "react-alert";
import "antd/dist/antd.css";
import "./Home.css";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { renderContent, renderControls } from "./ContentSwitcher";
import { urlJoinPath } from "../../Util/url";

import NavigationMenu from "../../Containers/NavigationMenu/NavigationMenu";

const { Header, Sider, Content } = Layout;

const siderStyle = { backgroundColor: "white", borderRadius: 10 };

const StudyOverview = (props) => {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    APIUtility.API.verifyLSToken(() => setIsLoading(false));
  }, []);

  const toggle = (collapsed) => {
    props.setSiderCollapsed(collapsed);
  };

  const onMenuItemClicked = (item) => {
    props.setActiveContent([item.key]);
    if (item.key === "logoff") {
      APIUtility.API.makeAPICall(APIUtility.LOG_OFF).then((response) => {
        APIUtility.API.revokeUserAuthorizationFromStore();
      });
    }
  };

  const downloadTableData = () => {
    const [eventType, eventStatus] = props.activeContent[0].split("|");
    let paramString = urlJoinPath(eventType, eventStatus);

    if (props.showDetailReport) {
      paramString = urlJoinPath(paramString, "?showDetail=true");
    }

    setIsDownloading(true);
    APIUtility.API.makeAPICall(APIUtility.GET_PATIENT_EVENT_STATUS_CSV, paramString)
      .then((response) => response.text())
      .then((body) => {
        const file = new Blob([body], { type: "text/plain;charset=utf-8" });
        const FileSaver = require("file-saver");
        FileSaver.saveAs(file, eventType + "-" + eventStatus + ".csv");
        setIsDownloading(false);
      })
      .catch(() => {
        alert.show("Failed to download data, please try again.");
        setIsDownloading(false);
      });
  };

  if (isLoading) {
    return <Spin />;
  }

  if (!props.isAuthorized) {
    console.log("Home page redirecting to /sign-in");
    return <Redirect to="/sign-in" />;
  }

  if (props.isAuthorized && props.changePassword) {
    console.log("Home page redirecting to /setpassword");
    return <Redirect to="/setpassword" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={props.siderCollapsed}
        collapsedWidth={0}
        width={220}
        theme="light"
        style={siderStyle}
        trigger={null}
      >
        {props.siderCollapsed ? null : (
          <CloseCircleOutlined
            className="trigger"
            type={props.siderCollapsed ? "menu-unfold" : "menu-fold"}
            onClick={() => props.setSiderCollapsed(!props.siderCollapsed)}
          ></CloseCircleOutlined>
        )}
        <div className="logo">
          <a href="https://rubytracker.ca">
            <img src="/ruby_logo_red.png" style={{ display: "block", margin: "auto", paddingTop: 7 }} />
          </a>
        </div>
        <NavigationMenu
          menuSelections={props.activeContent}
          onMenuItemClicked={onMenuItemClicked}
          onMenuItemSelected={onMenuItemClicked}
        />
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          <Row gutter={16}>
            <Col span={2}>
              {props.siderCollapsed ? (
                <Button
                  // type="primary"
                  shape="circle"
                  icon={<ArrowRightOutlined />}
                  onClick={() => props.setSiderCollapsed(!props.siderCollapsed)}
                />
              ) : null}
            </Col>
            {renderControls(props.activeContent[0], downloadTableData, isDownloading)}
          </Row>
        </Header>
        <Content style={{ margin: "24px 16px", padding: 24, background: "#fff", minHeight: 500 }}>
          {renderContent(props.activeContent[0])}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthorized: state.authentication.isAuthorized,
    activeContent: state.navigation.activeContent,
    siderCollapsed: state.navigation.siderCollapsed,
    showDetailReport: state.patientCompletionStatusTable.showAdditionalColumns,
    changePassword: state.authentication.userChangePassword,
    isServerDown: state.authentication.isServerDown,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuthorized: (authBool) => dispatch(actions.setIsAuthorized(authBool)),
    setActiveContent: (page) => dispatch(actions.setActiveContent(page)),
    setSiderCollapsed: (isCollapsed) => dispatch(actions.setMenuSiderCollapsed(isCollapsed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyOverview);
