import React from "react";
import { Row, Col, Tag } from "antd";
import { PieChartOutlined } from "@ant-design/icons";
import { COLORS } from "./PieConstants";

const tagStyle = {
  width: "100px",
};

const PieChartLegend = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
          <Tag color={COLORS.Completed} style={tagStyle}>
            <PieChartOutlined /> Completed
          </Tag>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
          <Tag color={COLORS["Not started"]} style={tagStyle}>
            <PieChartOutlined /> Not started
          </Tag>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
          <Tag color={COLORS.Incomplete} style={tagStyle}>
            <PieChartOutlined /> Incomplete
          </Tag>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
          <Tag color={COLORS.Withdrawn} style={tagStyle}>
            <PieChartOutlined /> Withdrawn
          </Tag>
        </Col>
        {/* <Col xs={12} sm={6} md={4} lg={3} xl={3}>
          <Tag color={COLORS.Pending} style={tagStyle}>
            <Icon type="pie-chart" /> Pending
          </Tag>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default PieChartLegend;
