import withRoot from "../../Containers/withRoot";
// --- Post bootstrap -----
import React from "react";

import AppAppBar from "../../Containers/views/AppAppBar";
import AppFooter from "../../Containers/views/AppFooter";
import DataTabs from "./DataTabs";

const DataExplorer = () => {
  return (
    <React.Fragment>
      <AppAppBar />
      <div className="data-explorer-card-wrapper">
        <DataTabs />
      </div>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(DataExplorer);
