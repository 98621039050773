import withRoot from "../../Containers/withRoot";
// --- Post bootstrap -----
import { Redirect } from "react-router";
import * as APIUtility from "../../Util/api";
import { useAlert } from "react-alert";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "../../Components/Typography/Typography";
import AppFooter from "../../Containers/views/AppFooter";
import AppAppBar from "../../Containers/views/AppAppBar";
import AppForm from "../../Containers/views/AppForm";
import TextField from "@material-ui/core/TextField";
import Button from "../../Components/Button/Button";

import MenuItem from "@material-ui/core/MenuItem";
import { isValidEmail, isValidPassword } from "../../Util/inputValidation";
const lodash = require("lodash");

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

const SignUp = (props) => {
  const alert = useAlert();
  const classes = useStyles();

  const [signUpDisabled, setSignUpDisabled] = useState(false);

  const [siteOptions, setSiteOptions] = useState({});
  const [sent, setSent] = useState(false);
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);

  const [siteSelected, setSiteSelected] = useState("");

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelper, setUsernameHelper] = useState("");

  const [fullname, setFullname] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelper, setEmailHelper] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState("");

  const [password2, setPassword2] = useState("");
  const [password2Error, setPassword2Error] = useState(false);
  const [password2Helper, setPassword2Helper] = useState("");

  //Obtain list of site options
  useEffect(() => {
    APIUtility.API.makeAPICall(APIUtility.GET_SIGN_UP_SITE_OPTIONS).then((response) => {
      if (!response) {
        setSignUpDisabled(true);
      } else if (response.status == 200) {
        response.json().then((response) => {
          setSiteOptions(response);
        });
      } else {
        setSignUpDisabled(true);
      }
    });
  }, []);

  const onKeyPress = (e) => {
    if (e.which === 13) {
      createUser();
    }
  };

  const createUser = () => {
    if (!username) return alert.show("Please fill out username.");
    if (usernameError) return alert.show("Please check username format.");
    if (!fullname) return alert.show("Please fill out your fullname.");
    if (!email) return alert.show("Please fill out email address.");
    if (emailError) return alert.show("Please check email address format.");
    if (!password) return alert.show("Please fill out password.");
    if (passwordError) return alert.show("Please enter a password that meets minimum requirements.");
    if (!password2) return alert.show("Please fill out password again in the confirmation field.");
    if (password2Error) return alert.show("Please enter the same password in the confirmation field.");

    setSent(true);
    const data = { email: email, username: username, password: password, fullname: fullname, site: siteSelected };

    APIUtility.API.makeAPICall(APIUtility.CREATE_USER, null, data).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status == 200) {
        setSignUpSuccessful(true);
      } else {
        response.text().then((result) => {
          alert.show(result);
          setSent(false);
        });
      }
    });
  };

  const onTextInputChange = (e) => {
    const inputFieldId = e.target.id || e.target.name;
    const inputValue = e.target.value;
    let isValid = true;

    switch (inputFieldId) {
      case "study-site":
        setSiteSelected(inputValue);
        break;

      case "username":
        isValid = isValidEmail(inputValue);
        setUsername(inputValue);
        setUsernameError(!isValid);
        setUsernameHelper(isValid ? "" : "Username can only contain alphanumeric characters.");
        break;
      case "fullname":
        setFullname(inputValue);
        break;
      case "email":
        isValid = isValidEmail(inputValue);
        setEmail(inputValue);
        setEmailError(!isValid);
        setEmailHelper(isValid ? "" : "Please enter a valid email address.");
        break;
      case "password":
        isValid = isValidPassword(inputValue);
        setPassword(inputValue);
        setPassword2("");
        setPasswordError(!isValid);
        setPasswordHelper(
          isValid
            ? ""
            : "Password must be at least 8 characters long, containing both a upper and lower case letter, a number, and a special character such as one of the following: !@#$%^*."
        );
        break;
      case "password2":
        isValid = inputValue === password;
        setPassword2(inputValue);
        setPassword2Error(!isValid);
        setPassword2Helper(isValid ? "" : "Please enter the same password.");
    }
  };

  if (signUpSuccessful) {
    return <Redirect to="/sign-up-success" />;
  }

  const signUpForm = (
    <form className={classes.form} noValidate>
      <TextField
        variant="outlined"
        select
        margin="normal"
        fullWidth
        id="study-site"
        disabled={sent}
        label="Which site?"
        name="study-site"
        value={siteSelected}
        onChange={onTextInputChange}
      >
        {Object.entries(siteOptions).map(([site, siteName]) => (
          <MenuItem value={site} key={site}>
            {siteName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        disabled={sent}
        label="Create a username"
        name="username"
        value={username}
        autoComplete="username"
        onChange={onTextInputChange}
        autoFocus
        error={usernameError}
        helperText={usernameHelper}
        onKeyPress={onKeyPress}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="fullname"
        disabled={sent}
        label="Your full name"
        name="name"
        value={fullname}
        autoComplete="name"
        onChange={onTextInputChange}
        onKeyPress={onKeyPress}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        disabled={sent}
        label="Your email address"
        name="email"
        value={email}
        error={emailError}
        helperText={emailHelper}
        autoComplete="email"
        onChange={onTextInputChange}
        onKeyPress={onKeyPress}
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        disabled={sent}
        name="password"
        label="Password"
        type="password"
        id="password"
        value={password}
        error={passwordError}
        helperText={passwordHelper}
        autoComplete="new-password"
        onChange={onTextInputChange}
        onKeyPress={onKeyPress}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        disabled={sent}
        name="password"
        label="Re-enter password"
        type="password"
        id="password2"
        value={password2}
        error={password2Error}
        helperText={password2Helper}
        autoComplete="new-password"
        onChange={onTextInputChange}
        onKeyPress={onKeyPress}
      />

      <Button
        onClick={createUser}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={sent}
      >
        Register
      </Button>
    </form>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h6" gutterBottom marked="center" align="center">
            Site Coordinator Registration
          </Typography>
          <Typography variant="body2" align="center">
            {"Already registered? "}
            <Link href="/sign-in" underline="always">
              Sign In
            </Link>
          </Typography>
        </React.Fragment>

        {signUpDisabled ? (
          <Typography variant="body2" align="center">
            <br />
            User sign up is disabled, please contact administrator to create an account for you.
          </Typography>
        ) : (
          signUpForm
        )}
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(SignUp);
