import * as actionTypes from "./actionTypes";

export const setActiveContent = (activeContent) => {
  return {
    type: actionTypes.SET_NAVIGATION_CURRENT_CONTENT,
    activeContent,
  };
};

export const setMenuSiderCollapsed = (isCollapsed) => {
  return {
    type: actionTypes.SET_MENU_SIDER_COLLAPSED,
    isCollapsed,
  };
};
