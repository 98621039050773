export const parseData = (data) => {
  return data;
};

/**
 * Method that returns a list of table columns
 * @param {boolean} anchorLeftColumnBool Boolean value to indicate whether to anchor some columns to the left.
 * @param {function} getColumnSearchProps Function used to search column in table.
 * @param {boolean} showMoreTableColumns Boolean value to indicate whether to show/hide some columns
 */
export const makeDataColumnsFunc = (anchorLeftColumnBool, getColumnSearchProps, showMoreTableColumns) => {
  // if (showMoreTableColumns) {
  //   return [...fixedColumns(anchorLeftColumnBool, getColumnSearchProps), ...additionalColumns];
  // } else {
  return fixedColumns(false, getColumnSearchProps);
  // }
};

const fixedColumns = (anchorLeftColumnBool, getColumnSearchProps) => [
  {
    title: "Patient Id",
    dataIndex: "patient_id",
    key: "patient_id",
    width: 120,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.id, b.id),
    // ...getColumnSearchProps("id")
  },
  {
    title: "Site",
    dataIndex: "site_name",
    key: "site_name",
    width: 140,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.site, b.site),
    // ...getColumnSearchProps("site_name")
  },
  // {
  //   title: "Consent",
  //   dataIndex: "consent_descript",
  //   key: "consent_descript",
  //   width: 95,
  //   sorter: (a, b) => sortString(a.consent, b.consent),
  // },
  {
    title: "Enrollment",
    dataIndex: "enrollment_date",
    key: "enrollment_date",
    width: 120,
  },
  {
    title: "Group",
    dataIndex: "ruby_group",
    key: "ruby_group",
    width: 80,
    sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
    filters: [
      { text: "PRO A", value: "PRO A" },
      { text: "PRO B", value: "PRO B" },
    ],
    filterMultiple: false,
  },
  // {
  //   title: "Gift Status",
  //   dataIndex: "gift_card_status",
  //   key: "gift_card_status",
  //   width: 100,
  //   sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  // },
  {
    title: "Outstanding Surveys",
    dataIndex: "num_outstanding_surveys",
    key: "num_outstanding_surveys",
    width: 80,
    filters: [
      { text: "<=1", value: "lte:1" },
      { text: ">1", value: "gt:1" },
      { text: "<=2", value: "lte:2" },
      { text: ">2", value: "gt:2" },
      { text: "<=3", value: "lte:3" },
      { text: ">3", value: "gt:3" },
      { text: "<=5", value: "lte:5" },
      { text: ">5", value: "gt:5" },
      { text: "<=10", value: "lte:10" },
      { text: ">10", value: "gt:10" },
      { text: "<=15", value: "lte:15" },
      { text: ">15", value: "gt:15" },
      { text: "<=20", value: "lte:20" },
      { text: ">20", value: "gt:20" },
      { text: "<=25", value: "lte:25" },
      { text: ">25", value: "gt:25" },
    ],
    filterMultiple: false,
  },
  {
    title: "Pass 90 Day Window",
    dataIndex: "pass_reminder_window",
    key: "pass_reminder_window",
    width: 80,
    filters: [
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
    ],
    filterMultiple: false,
  },
  // {
  //   title: "FFQ Complete",
  //   dataIndex: "ffq_complete",
  //   key: "ffq_complete",
  //   width: 80,
  //   filters: [
  //     { text: "Yes", value: "Yes" },
  //     { text: "No", value: "No" },
  //   ],
  //   filterMultiple: false,
  // },

  // {
  //   title: "Days in Current Event",
  //   dataIndex: "days_in_cur_event",
  //   key: "days_in_cur_event",
  //   width: 80,
  //   filters: [
  //     { text: ">7", value: "gt:7" },
  //     { text: ">14", value: "gt:14" },
  //     { text: ">30", value: "gt:30" },
  //     { text: ">45", value: "gt:45" },
  //     { text: ">60", value: "gt:60" },
  //     { text: ">90", value: "gt:90" },
  //     { text: ">120", value: "gt:120" },
  //     { text: ">180", value: "gt:180" },
  //     { text: ">275", value: "gt:275" },
  //     { text: ">365", value: "gt:365" },
  //     { text: ">500", value: "gt:500" },
  //     { text: ">730", value: "gt:730" },
  //   ],
  //   filterMultiple: false,
  //   // filteredValue: filterProps.filterColumn || null,
  //   // onFilter: filterProps.onFilter,
  // },
];

const additionalColumns = [
  /////// PRO A Only surveys /////////
  {
    title: "PRO A Survey Completion Dates",
    children: [
      {
        title: "Feelings of Depression",
        dataIndex: "Feelings of Depression",
        key: "feelings_of_depression",
        width: 120,
      },
      {
        title: "Trauma Checklist",
        dataIndex: "Trauma Checklist",
        key: "trauma_checklist",
        width: 120,
      },
      {
        title: "Pittsburgh Sleep Quality Index",
        dataIndex: "Pittsburgh Sleep Quality Index",
        key: "pittsburgh_sleep_quality_index",
        width: 120,
      },
      {
        title: "Marital Adjustment",
        dataIndex: "Marital Adjustment",
        key: "marital_adjustment",
        width: 120,
      },
    ],
  },
  //////// PRO A & B surveys ///////////

  {
    title: "PRO A & PRO B Survey Completion Dates",
    children: [
      {
        title: "Breast Cancer Diagnosis & Treatment",
        dataIndex: "Breast Cancer Diagnosis & Treatment",
        key: "breast_cancer_diagnosis_treatment",
        width: 120,
      },
      {
        title: "Breast Imaging & Surgeries",
        dataIndex: "Breast Imaging & Surgeries",
        key: "breast_imaging_surgeries",
        width: 120,
      },
      {
        title: "Menstrual & Reproductive History",
        dataIndex: "Menstrual & Reproductive History",
        key: "menstrual_reproductive_history",
        width: 120,
      },
      {
        title: "Birth Control History",
        dataIndex: "Birth Control History",
        key: "birth_control_history",
        width: 120,
      },
      {
        title: "Medical History",
        dataIndex: "Medical History",
        key: "medical_history",
        width: 120,
      },
      {
        title: "Demographics",
        dataIndex: "Demographics",
        key: "demographics",
        width: 120,
      },
      {
        title: "BREAST-Q",
        dataIndex: "BREAST-Q",
        key: "breastq",
        width: 120,
      },
      {
        title: "Cancer Behavior Inventory",
        dataIndex: "Cancer Behavior Inventory (CBI-B)",
        key: "cancer_behavior_inventory_cbib",
        width: 120,
      },
      {
        title: "General Anxiety",
        dataIndex: "General Anxiety",
        key: "general_anxiety",
        width: 120,
      },
      {
        title: "Personality Survey",
        dataIndex: "Personality Survey",
        key: "personality_survey",
        width: 120,
      },
      {
        title: "Lifestyle History",
        dataIndex: "Lifestyle History",
        key: "lifestyle_history",
        width: 120,
      },
      {
        title: "Physical Activity & Sedentary Behaviour",
        dataIndex: "physical_activity_sedentary_behaviour_questionnair",
        key: "physical_activity_sedentary_behaviour_questionnair",
        width: 120,
      },
      {
        title: "Food Frequency Questionnaire",
        dataIndex: "Food Frequency Questionnaire (FFQ)",
        key: "ffq",
        width: 120,
      },
      {
        title: "Family History",
        dataIndex: "Family History",
        key: "family_history",
        width: 120,
      },
    ],
  },
  //////// PRO B only surveys /////////
  {
    title: "PRO B Survey Completion Dates",
    children: [
      {
        title: "Side Effect Expectations",
        dataIndex: "Side Effect Expectations",
        key: "side_effect_expectations",
        width: 120,
      },
      {
        title: "Breast Cancer Prevention",
        dataIndex: "bcpt_symptom_checklist",
        key: "bcpt_symptom_checklist",
        width: 120,
      },
      {
        title: " Cancer Fatigue Scale",
        dataIndex: "Cancer Fatigue Scale",
        key: "cancer_fatigue_scale_plus_scoring",
        width: 120,
      },
      {
        title: "PHQ-9",
        dataIndex: "PhQ-9",
        key: "phq9",
        width: 120,
      },
      {
        title: "Medical Outcomes Social Support",
        dataIndex: "Medical Outcomes Social Support Survey",
        key: "medical_outcomes_social_support_survey",
        width: 120,
      },
      {
        title: "Brief Illness Perception",
        dataIndex: "Brief Illness Perception Questionnaire",
        key: "illness_beliefs",
        width: 120,
      },
      {
        title: "Beliefs About Cancer Treatment(s)",
        dataIndex: "beliefs_about_medicines",
        key: "beliefs_about_medicines",
        width: 120,
      },
    ],
  },
];

const sortString = (stringA, stringB) => {
  return ("" + stringA).localeCompare(stringB);
};
