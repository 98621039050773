import store from "../../Store/configureStore";
import * as actions from "../../Store/Actions/index";
const lodash = require("lodash");

export const COLORS = {
  Completed: "#3a9970",
  Incomplete: "#FFCD32",
  "Not started": "#86cef0",
  Withdrawn: "#adadad",
  Pending: "#F26522",
  "In-focus": "#ffb3bb",
  Label: "#121212",
};

export const queryKeyLookup = {
  Completed: "completed",
  "Not started": "not_started",
  Incomplete: "incomplete",
  Withdrawn: "withdrawn",
  Pending: "pending",
};

export const PIE_SIZES = {
  1: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
  2: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
  3: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
  4: { xs: 12, sm: 12, md: 8, lg: 6, xl: 6 },
  5: { xs: 12, sm: 12, md: 6, lg: 4, xl: 4 },
};

export const PIE_DATA_DEFAULT = [
  {
    color: COLORS.completed,
    title: "Completed",
    value: 1,
  },
  {
    color: COLORS.not_started,
    title: "Not started",
    value: 1,
  },
  {
    color: COLORS.incomplete,
    title: "Incomplete",
    value: 1,
  },
  // {
  //   color: COLORS.pending,
  //   title: "Pending",
  //   value: 1,
  // },
  {
    color: COLORS.withdrawn,
    title: "Withdrawn",
    value: 1,
  },
];

/**
 * Parse and set pie chart data to store.
 * @param {*} apiData The data received.
 * @param {*} setData The setter method for setting parsed data to store.
 */
export const PARSE_SET_CHART_DATA = (apiData) => {
  store.dispatch(
    actions.setDataCompStatus({
      // siteOptions: lodash.get(apiData, "site_options"),
      baseline: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_baseline_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_baseline_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_baseline_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_baseline_withdrawn") | 0,
        },
      ],
      threemonth: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_3_month_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_3_month_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_3_month_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_3_month_withdrawn") | 0,
        },
        {
          color: COLORS.Pending,
          title: "Pending",
          value: lodash.get(apiData, "patient_count_3_month_pending") | 0,
        },
      ],
      oneyear: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_1_year_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_1_year_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_1_year_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_1_year_withdrawn") | 0,
        },
        {
          color: COLORS.Pending,
          title: "Pending",
          value: lodash.get(apiData, "patient_count_1_year_pending") | 0,
        },
      ],
      twoyear: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_2_year_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_2_year_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_2_year_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_2_year_withdrawn") | 0,
        },
        {
          color: COLORS.Pending,
          title: "Pending",
          value: lodash.get(apiData, "patient_count_2_year_pending") | 0,
        },
      ],
      threeyear: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_3_year_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_3_year_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_3_year_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_3_year_withdrawn") | 0,
        },
        {
          color: COLORS.Pending,
          title: "Pending",
          value: lodash.get(apiData, "patient_count_3_year_pending") | 0,
        },
      ],
      posttreatment: [
        {
          color: COLORS.Completed,
          title: "Completed",
          value: lodash.get(apiData, "patient_count_post_treatment_complete") | 0,
        },
        {
          color: COLORS["Not started"],
          title: "Not started",
          value: lodash.get(apiData, "patient_count_post_treatment_not_started") | 0,
        },
        {
          color: COLORS.Incomplete,
          title: "Incomplete",
          value: lodash.get(apiData, "patient_count_post_treatment_incomplete") | 0,
        },
        {
          color: COLORS.Withdrawn,
          title: "Withdrawn",
          value: lodash.get(apiData, "patient_count_post_treatment_withdrawn") | 0,
        },
        {
          color: COLORS.Pending,
          title: "Pending",
          value: lodash.get(apiData, "patient_count_post_treatment_pending") | 0,
        },
      ],
    })
  );
};
