import * as APIUtility from "../../Util/api";

import withRoot from "../../Containers/withRoot";
// --- Post bootstrap -----
import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
// import TextField from "../../Components/TextField/TextField";
import Button from "../../Components/Button/Button";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "../../Components/Typography/Typography";
import AppFooter from "../../Containers/views/AppFooter";
import AppAppBar from "../../Containers/views/AppAppBar";
import AppForm from "../../Containers/views/AppForm";
import { useAlert } from "react-alert";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { isValidPassword } from "../../Util/inputValidation";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

const ChangeTemporaryPassword = (props) => {
  const classes = useStyles();
  const alert = useAlert();

  const [sent, setSent] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState("");

  const [password2, setPassword2] = useState("");
  const [password2Error, setPassword2Error] = useState(false);
  const [password2Helper, setPassword2Helper] = useState("");

  const submit = () => {
    if (!oldPassword) return alert.show("Please fill out old password.");
    if (!password) return alert.show("Please fill out new password.");
    if (passwordError) return alert.show("Please enter a password that meets minimum requirements.");
    if (!password2) return alert.show("Please fill out password again in the confirmation field.");
    if (password2Error) return alert.show("Please enter the same password in the confirmation field.");

    setSent(true);
    const data = { old_password: oldPassword, new_password: password };
    APIUtility.API.makeAPICall(APIUtility.CHANGE_PASSWORD, null, data).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status === 200) {
        alert.show("Password updated successfully.");
        props.setChangePassword(false);
      } else {
        response.text().then((result) => {
          alert.show(result);
          setSent(false);
        });
      }
    });
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      submit();
    }
  };

  const onTextInputChange = (e) => {
    const inputFieldId = e.target.id || e.target.name;
    const inputValue = e.target.value;
    let isValid = true;

    switch (inputFieldId) {
      case "oldpassword":
        setOldPassword(inputValue);
        break;

      case "password":
        isValid = isValidPassword(inputValue);
        setPassword(inputValue);
        setPassword2("");
        setPasswordError(!isValid);
        setPasswordHelper(
          isValid
            ? ""
            : "Password must be at least 8 characters long, containing both a upper and lower case letter, a number, and a special character such as one of the following: !@#$%^*."
        );
        break;

      case "password2":
        isValid = inputValue === password;
        setPassword2(inputValue);
        setPassword2Error(!isValid);
        setPassword2Helper(isValid ? "" : "Please enter the same password.");
        break;
    }
  };

  if (!props.isAuthorized) {
    return <Redirect to="/sign-in" />;
  }

  if (props.isAuthorized && !props.changePassword) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Almost ready!
          </Typography>
          <Typography variant="body2" align="center">
            Please set up a new password.
          </Typography>
        </React.Fragment>

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="oldpassword"
            label="Current/temporary Password"
            type="password"
            id="oldpassword"
            value={oldPassword}
            autoComplete="current-password"
            onChange={onTextInputChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={sent}
            name="password"
            label="New Password"
            type="password"
            id="password"
            value={password}
            error={passwordError}
            helperText={passwordHelper}
            autoComplete="new-password"
            onChange={onTextInputChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={sent}
            name="password"
            label="Confirm new password"
            type="password"
            id="password2"
            value={password2}
            error={password2Error}
            helperText={password2Helper}
            autoComplete="new-password"
            onChange={onTextInputChange}
            onKeyPress={onKeyPress}
          />
          <Button onClick={submit} fullWidth variant="contained" color="primary" className={classes.submit}>
            Submit
          </Button>
        </form>

        {/* <Typography align="center">
          <Link underline="always" href="/forgot-password">
            Forgot password?
          </Link>
        </Typography> */}
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
};

// export default withRoot(SignIn);

const mapStateToProps = (state) => {
  return {
    isAuthorized: state.authentication.isAuthorized,
    changePassword: state.authentication.userChangePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuthorized: (authBool) => dispatch(actions.setIsAuthorized(authBool)),
    setChangePassword: (bool) => dispatch(actions.setUserChangePassword(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(ChangeTemporaryPassword));
