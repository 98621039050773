import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  pieSize: 3,
  showMorePieCharts: false,
  data: null,
  siteOptions: null,
  selectedSites: [],
};

const reducer = (state = initialState, action) => {
  const calculateNewRadius = (increase) => {
    let size;
    if (increase) {
      size = state.pieSize - 1;
    } else {
      size = state.pieSize + 1;
    }

    if (size > 5) {
      size = 5;
    } else if (size < 1) {
      size = 1;
    }

    return size;
  };

  switch (action.type) {
    case actionTypes.INCREASE_PIE_CHART_SIZE_COMP_STATUS:
      return { ...state, pieSize: calculateNewRadius(true) };
    case actionTypes.REDUCE_PIE_CHART_SIZE_COMP_STATUS:
      return { ...state, pieSize: calculateNewRadius(false) };
    case actionTypes.TOGGLE_SHOW_MORE_PIE_CHART_COMP_STATUS:
      return { ...state, showMorePieCharts: !state.showMorePieCharts };
    case actionTypes.SET_DATA_COMP_STATUS:
      return { ...state, data: action.data };
    case actionTypes.SET_PIE_CHART_SITES_SELECTED:
      return { ...state, selectedSites: action.selection };
    case actionTypes.SET_PIE_CHART_SITE_OPTIONS:
      return { ...state, siteOptions: action.options };
    default:
      return state;
  }
};

export default reducer;
