import * as actionTypes from "./actionTypes";

export const setDataSummaryOptions = (dataSummaryOptions) => {
  return {
    type: actionTypes.SET_DATA_SUMMARY_OPTIONS,
    dataSummaryOptions,
  };
};

export const setDataSummary = (data) => {
  return {
    type: actionTypes.SET_DATA_SUMMARY,
    data,
  };
};

export const setDataDictionary = (dataDictionary) => {
  return {
    type: actionTypes.SET_DATA_DICTIONARY,
    dataDictionary,
  };
};

export const setSurveyEventTalbe = (data) => {
  return {
    type: actionTypes.SET_EVENT_SURVEY_TABLE,
    data,
  };
};

export const setDataSummarySankey = (data) => {
  return {
    type: actionTypes.SET_DATA_SUMMARY_SANKEY,
    data,
  };
};
