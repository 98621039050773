import React, { useState } from "react";
import * as APIUtility from "../../Util/api";
import { connect } from "react-redux";

import { AutoComplete, Descriptions, Badge, Menu, Tag, Dropdown, Button, Modal } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { useAlert } from "react-alert";

const lodash = require("lodash");
const buttonStyle = { margin: 10 };
const DELETE_BUTTON = "DELETE_BUTTON";
const RESET_BUTTON = "RESET_BUTTON";
const SAVE_BUTTON = "SAVE_BUTTON";

const ManageUserProfile = (props) => {
  const alert = useAlert();

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [changed, setChanged] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [modal, contextHolder] = Modal.useModal();

  const onSearch = (searchText) => {
    if (searchText.length <= 3) {
      return;
    }
    const data = { search: searchText };
    APIUtility.API.makeAPICall(APIUtility.GET_USER_LIST, null, data).then((response) => {
      if (response) {
        response.json().then((results) => {
          setOptions(
            results.map((user) => {
              return { value: `Username: ${user.username} Email: ${user.email}`, user: user };
            })
          );
        });
      }
    });
  };

  const onSelect = (value, option) => {
    const user = option.user;
    setUser(user);
    setChanged(false);
    setOptions([]);
  };

  const onChange = (data) => {
    setValue(data);
    // clear input
    if (!data) {
      clear();
    }
  };

  /**
   * Clear and discard all changes.
   */
  const clear = () => {
    setValue("");
    setOptions([]);
    setUser(null);
    setChanged(false);
    setLoadingButtons({});
  };

  const renderAccountStatus = () => {
    if (!user) {
      return "";
    } else if (lodash.get(user, "is_active")) {
      return <Badge status="success" text="Active" />;
    } else {
      return <Badge status="error" text="Deactivated" />;
    }
  };

  const renderAccessPermission = () => {
    if (!user) {
      return "";
    } else if (lodash.get(user, "read_only")) {
      return <Badge status="success" text="Read-only" />;
    } else {
      return <Badge status="processing" text="Read+Write" />;
    }
  };

  const renderPasswordExpiry = () => {
    if (!user) {
      return "";
    } else if (lodash.get(user, "temp_pass")) {
      return <Badge status="error" text="Update Required" />;
    } else {
      return <Badge status="success" text="OK" />;
    }
  };

  const renderSites = () => {
    if (!user) return "";

    const sites = user.read_sites;

    const siteTags = sites.map((site) => (
      <Tag
        key={site}
        closable
        onClose={() => {
          closeSiteTag(site, user);
        }}
      >
        {displaySiteName(site)}
      </Tag>
    ));

    const plusTag = (
      <Tag className="site-tag-plus">
        <Dropdown overlay={sitesOptions()} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <PlusOutlined />
          </a>
        </Dropdown>
      </Tag>
    );

    return (
      <React.Fragment>
        {siteTags}
        {plusTag}
      </React.Fragment>
    );
  };

  const displaySiteName = (siteId) => {
    if (props.siteOptions) {
      const siteName = lodash.get(props.siteOptions, siteId);
      if (siteName) {
        return siteName;
      }
    }
    return siteId;
  };

  const closeSiteTag = (siteRemoved) => {
    const sites = [...user.read_sites];
    const modifiedSites = [...lodash.remove(sites, (item) => item !== siteRemoved)];
    const modifiedUser = { ...user, read_sites: modifiedSites };
    setUser(modifiedUser);
    setChanged(true);
  };

  const addSiteToUser = (e) => {
    const siteToAdd = e.key;
    const modifiedSites = [...Array.from(user.read_sites), siteToAdd];
    const modifiedUser = { ...user, read_sites: modifiedSites };
    setUser(modifiedUser);
    setChanged(true);
  };

  const sitesOptions = () => {
    const sites = lodash.get(props, "siteOptions");

    if (!sites) {
      return;
    }
    const siteMenuOptions = Object.entries(sites).map(([siteValue, siteName]) => (
      <Menu.Item key={siteValue} onClick={(e) => addSiteToUser(e)}>
        {siteName}
      </Menu.Item>
    ));

    return <Menu className="site-add-dropdown-menu">{siteMenuOptions}</Menu>;
  };

  /**
   * Set the status of a button in a dictionary of button states
   */
  const setButtonLoadingStatus = (key, bool) => {
    const buttonLoadingStatus = { ...loadingButtons };
    buttonLoadingStatus[key] = bool;
    setLoadingButtons(buttonLoadingStatus);
  };

  const saveChanges = () => {
    setButtonLoadingStatus(SAVE_BUTTON, true);
    APIUtility.API.makeAPICall(APIUtility.ADMIN_UPDATE_USER_PROFILE, null, user).then((response) => {
      if (!response) {
        alert.show("Something went wrong, please try again.");
        setButtonLoadingStatus(SAVE_BUTTON, false);
      } else if (response.status === 200) {
        response.json().then((result) => {
          modal.success({
            title: "Saved!",
            content: result.message,
            onOk: saveChangesSuccessActions,
          });
        });
      }
    });
  };

  const saveChangesSuccessActions = () => {
    setButtonLoadingStatus(SAVE_BUTTON, false);
    setChanged(false);
  };

  const resetUserPassword = () => {
    setButtonLoadingStatus(RESET_BUTTON, true);
    APIUtility.API.makeAPICall(APIUtility.ADMIN_RESET_USER_PASSWORD, user.id).then((response) => {
      if (!response) {
        alert.show("Something went wrong, please try again.");
        setButtonLoadingStatus(RESET_BUTTON, false);
      } else if (response.status === 200) {
        response.json().then((result) => {
          modal.success({
            title: "Password reset success!",
            content: `Please record and send the password to user before closing this dialogue. 
            \nUsername: ${user.username}
            \nTemporary password: ${result.password}`,
            onOk: resetUserSuccessActions,
          });
        });
      }
    });
  };

  const resetUserSuccessActions = () => {
    setButtonLoadingStatus(RESET_BUTTON, false);
    const modifiedUser = { ...user };
    modifiedUser.temp_pass = true;
    setUser(modifiedUser);
  };

  const deleteUserAccount = () => {
    setButtonLoadingStatus(DELETE_BUTTON, true);
    APIUtility.API.makeAPICall(APIUtility.ADMIN_DELETE_USER_ACCOUNT, user.id).then((response) => {
      if (!response) {
        alert.show("Something went wrong, please try again.");
        setButtonLoadingStatus(DELETE_BUTTON, false);
      } else if (response.status === 200) {
        response.json().then((result) => {
          alert.show(result.message);
        });
        clear();
      }
    });
  };

  return (
    <React.Fragment>
      <AutoComplete
        value={value}
        options={options}
        style={{ width: 500 }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        allowClear
        placeholder="Search for username or email address"
      />
      <br />
      <br />
      <Descriptions
        title="User Info"
        bordered
        column={2}
        extra={changed ? "Unsaved changes, click 'Submit' button below to save changes." : ""}
      >
        <Descriptions.Item label="Username" span={1}>
          {lodash.get(user, "username")}
        </Descriptions.Item>
        <Descriptions.Item label="Account Type">{lodash.get(user, "role")}</Descriptions.Item>
        <Descriptions.Item label="Email" span={1}>
          {lodash.get(user, "email")}
        </Descriptions.Item>
        <Descriptions.Item label="Account Status">{renderAccountStatus()}</Descriptions.Item>
        <Descriptions.Item label="Last Log-in" span={1}>
          {lodash.get(user, "last_login")}
        </Descriptions.Item>
        <Descriptions.Item label="Access">{renderAccessPermission()}</Descriptions.Item>
        <Descriptions.Item label="Account Creation" span={1}>
          {lodash.get(user, "date_joined")}
        </Descriptions.Item>
        <Descriptions.Item label="Password Status">{renderPasswordExpiry()}</Descriptions.Item>
        <Descriptions.Item label="Sites" span={2}>
          {renderSites()}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Button
        style={buttonStyle}
        disabled={!changed}
        loading={loadingButtons[SAVE_BUTTON]}
        type="primary"
        onClick={() => {
          modal.confirm({
            title: "Save?",
            content: "Save profile changes made to this user?",
            onOk: saveChanges,
          });
        }}
      >
        Save
      </Button>
      <Button
        style={buttonStyle}
        disabled={!changed}
        onClick={clear}
        onClick={() => {
          modal.confirm({
            title: "Cancel?",
            content: "Discard profile changes made to this user?",
            onOk: clear,
          });
        }}
      >
        Cancel
      </Button>
      <Button
        style={buttonStyle}
        disabled={!user}
        loading={loadingButtons[RESET_BUTTON]}
        danger
        onClick={() => {
          modal.confirm({
            title: "Reset password?",
            content:
              "User password will be reset to a temporary password, you must copy and send the password to the user. \
            The user will be asked to set up a new password once logged in using the temporary password.",
            onOk: resetUserPassword,
          });
        }}
      >
        Reset Password
      </Button>
      <Button
        style={buttonStyle}
        disabled={!user}
        loading={loadingButtons[DELETE_BUTTON]}
        danger
        type="primary"
        onClick={() => {
          modal.confirm({
            title: "Delete user?",
            content: "Permanently delete user account?",
            onOk: deleteUserAccount,
          });
        }}
      >
        Delete Account
      </Button>

      {contextHolder}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    siteOptions: state.studyCompletionStatus.siteOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserProfile);
