import * as actionTypes from "./actionTypes";

export const increasePieChartSizeCompStatus = () => {
  return {
    type: actionTypes.INCREASE_PIE_CHART_SIZE_COMP_STATUS,
  };
};

export const reducePieChartSizeCompStatus = () => {
  return {
    type: actionTypes.REDUCE_PIE_CHART_SIZE_COMP_STATUS,
  };
};

export const toggleShowMorePieChartCompStatus = () => {
  return {
    type: actionTypes.TOGGLE_SHOW_MORE_PIE_CHART_COMP_STATUS,
  };
};

export const setDataCompStatus = (data) => {
  return {
    type: actionTypes.SET_DATA_COMP_STATUS,
    data,
  };
};

export const setSiteSelected = (selection) => {
  return {
    type: actionTypes.SET_PIE_CHART_SITES_SELECTED,
    selection,
  };
};

export const setSiteOptions = (options) => {
  return {
    type: actionTypes.SET_PIE_CHART_SITE_OPTIONS,
    options,
  };
};
