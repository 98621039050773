import React from "react";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Menu } from "antd";
import { SettingOutlined, PieChartOutlined, BarsOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

const NavigationMenu = (props) => {
  const renderAdminSettingsMenu = () => {
    if (props.userRole === "admin") {
      return (
        <SubMenu
          key="admin-setting-menu"
          title={
            <span>
              <SettingOutlined />
              <span>Admin Settings</span>
            </span>
          }
        >
          <Menu.Item key="admin-settings-user-activation">User Activation</Menu.Item>
          <Menu.Item key="admin-settings-create-user">Create User Account</Menu.Item>
          <Menu.Item key="admin-settings-user-profile">Manage User Account</Menu.Item>
        </SubMenu>
      );
    }
  };

  return (
    <Menu
      mode="inline"
      theme="light"
      selectedKeys={props.menuSelections}
      onClick={props.onMenuItemClicked}
      onSelect={props.onMenuItemSelected}
    >
      <Menu.Item key="dashboard">
        <PieChartOutlined />
        <span>Dashboard</span>
      </Menu.Item>

      {/* <Menu.Item key="data-summary">
        <BarChartOutlined />
        <span>Data Explorer</span>
      </Menu.Item> */}

      <Menu.Item key="patient-profile">
        <UserOutlined />
        <span>Patients</span>
      </Menu.Item>

      <SubMenu
        key="reports"
        title={
          <span>
            <BarsOutlined />
            <span>Reports</span>
          </span>
        }
      >
        <Menu.Item key="all-participants">All Participants</Menu.Item>

        <SubMenu
          key="baseline-reports"
          title={
            <span>
              <BarsOutlined />
              <span>Baseline</span>
            </span>
          }
        >
          <Menu.Item key="baseline|completed">Completed</Menu.Item>
          <Menu.Item key="baseline|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="baseline|not_started">Not started</Menu.Item>
          <Menu.Item key="baseline|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>

        <SubMenu
          key="threemonth-reports"
          title={
            <span>
              <BarsOutlined />
              <span>3-Month</span>
            </span>
          }
        >
          <Menu.Item key="threemonth|completed">Completed</Menu.Item>
          <Menu.Item key="threemonth|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="threemonth|not_started">Not started</Menu.Item>
          <Menu.Item key="threemonth|pending">Pending</Menu.Item>
          <Menu.Item key="threemonth|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>

        <SubMenu
          key="oneyear-reports"
          title={
            <span>
              <BarsOutlined />
              <span>1-Year</span>
            </span>
          }
        >
          <Menu.Item key="oneyear|completed">Completed</Menu.Item>
          <Menu.Item key="oneyear|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="oneyear|not_started">Not started</Menu.Item>
          <Menu.Item key="oneyear|pending">Pending</Menu.Item>
          <Menu.Item key="oneyear|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>

        <SubMenu
          key="twoyear-reports"
          title={
            <span>
              <BarsOutlined />
              <span>2-Year</span>
            </span>
          }
        >
          <Menu.Item key="twoyear|completed">Completed</Menu.Item>
          <Menu.Item key="twoyear|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="twoyear|not_started">Not started</Menu.Item>
          <Menu.Item key="twoyear|pending">Pending</Menu.Item>
          <Menu.Item key="twoyear|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>

        <SubMenu
          key="threeyear-reports"
          title={
            <span>
              <BarsOutlined />
              <span>3-Year</span>
            </span>
          }
        >
          <Menu.Item key="threeyear|completed">Completed</Menu.Item>
          <Menu.Item key="threeyear|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="threeyear|not_started">Not started</Menu.Item>
          <Menu.Item key="threeyear|pending">Pending</Menu.Item>
          <Menu.Item key="threeyear|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>

        <SubMenu
          key="posttreatment-reports"
          title={
            <span>
              <BarsOutlined />
              <span>Post Treatment</span>
            </span>
          }
        >
          <Menu.Item key="posttreatment|completed">Completed</Menu.Item>
          <Menu.Item key="posttreatment|incomplete">Incomplete</Menu.Item>
          <Menu.Item key="posttreatment|not_started">Not started</Menu.Item>
          <Menu.Item key="posttreatment|pending">Pending</Menu.Item>
          <Menu.Item key="posttreatment|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>
      </SubMenu>

      <SubMenu
        key="brocap"
        title={
          <span>
            <BarsOutlined />
            <span>Brocap</span>
          </span>
        }
      >
        <SubMenu
          key="brocap-baseline"
          title={
            <span>
              <BarsOutlined />
              <span>Baseline</span>
            </span>
          }
        >
          <Menu.Item key="brocap_baseline|completed">Completed</Menu.Item>
          <Menu.Item key="brocap_baseline|not_started">Not started</Menu.Item>
          <Menu.Item key="brocap_baseline|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>
        <SubMenu
          key="brocap-oneyear"
          title={
            <span>
              <BarsOutlined />
              <span>1-Year</span>
            </span>
          }
        >
          <Menu.Item key="brocap_oneyear|completed">Completed</Menu.Item>
          <Menu.Item key="brocap_oneyear|not_started">Not started</Menu.Item>
          <Menu.Item key="brocap_oneyear|pending">Pending</Menu.Item>
          <Menu.Item key="brocap_oneyear|withdrawn">Withdrawn</Menu.Item>
        </SubMenu>
      </SubMenu>

      <SubMenu
        key="other-reports"
        title={
          <span>
            <BarsOutlined />
            <span>Completion</span>
          </span>
        }
      >
        <Menu.Item key="baselineposttreatment|completed">B+P</Menu.Item>
        <Menu.Item key="baselineoneyear|completed">B+Y1</Menu.Item>
        <Menu.Item key="baselineoneyeartwoyear|completed">B+Y1+Y2</Menu.Item>
        <Menu.Item key="baselineoneyeartwoyearthreeyear|completed">B+Y1+Y2+Y3</Menu.Item>
        <Menu.Item key="baselineoneyeartwoyearthreeyearposttreatment|completed">B+Y1+Y2+Y3+P</Menu.Item>
      </SubMenu>

      <SubMenu
        key="user-setting-menu"
        title={
          <span>
            <SettingOutlined />
            <span>Settings</span>
          </span>
        }
      >
        <Menu.Item key="user-change-password">Change Password</Menu.Item>
      </SubMenu>

      {renderAdminSettingsMenu()}

      <Menu.Item key="logoff">
        <LogoutOutlined />
        <span>Log Off</span>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthorized: state.authentication.isAuthorized,
    userRole: state.authentication.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
