export const isValidEmail = (input) => {
  const regex = /^[a-zA-Z0-9._\-]+@{0,1}[a-zA-Z0-9.]+$/;
  const usernameRegex = input.match(regex);
  return usernameRegex ? true : false;
};

export const isValidPassword = (input) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const passwordRegex = input.match(regex);
  return passwordRegex ? true : false;
};
