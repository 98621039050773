import React from "react";

import {
  parseBaselineData,
  makeBaselineDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/baseline";

import {
  parseThreeMonthData,
  makeThreeMonthDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/threemonth";

import {
  parseOneYearData,
  makeOneYearDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/oneyear";

import {
  parseTwoYearData,
  makeTwoYearDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/twoyear";

import {
  parseThreeYearData,
  makeThreeYearDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/threeyear";

import {
  parsePostTreatmentData,
  makePostTreatmentDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/posttreatment";

import {
  parseBrocapBaselineData,
  makeBrocapBaselineDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/brocapbaseline";

import {
  parseBrocapOneYearData,
  makeBrocapOneYearDataColumnsFunc,
} from "../../Containers/PatientCompletionStatusTable/TableSpecifics/brocaponeyear";

import { parseData, makeDataColumnsFunc } from "../../Containers/PatientCompletionStatusTable/TableSpecifics/other";

import AdminActivateUsers from "../../Containers/AdminManagement/ActivationManagement";
import AdminCreateUser from "../../Containers/AdminManagement/CreateUserAccount";
import ManageUserProfile from "../../Containers/AdminManagement/ManageUserProfile";
import ChangePassword from "../../Containers/UserProfile/ChangePassword";

import PatientCompletionStatusTable from "../../Containers/PatientCompletionStatusTable/PatientCompletionStatusTable";
import PatientCompletionStatusTableControls from "../../Containers/PatientCompletionStatusTable/Controls";
import PieChartDashboard from "../../Containers/PieChartDashboard/PieChartDashboard";
import PieChartDashboardControls from "../../Containers/PieChartDashboard/Controls";

import PatientProfile from "../../Containers/PatientProfile/PatientProfile";
import PatientProfileControls from "../../Containers/PatientProfile/Controls";
import PatientsListTable from "../../Containers/PatientsListTable/PatientsListTable";
import PatientListsTableControls from "../../Containers/PatientsListTable/Controls";

export const renderControls = (content, downloadTableData, isDownloading) => {
  switch (content) {
    case "dashboard":
      return <PieChartDashboardControls />;

    case "patient-profile":
      return <PatientProfileControls />;

    case "all-participants":
      return <PatientListsTableControls />;

    case "baseline|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "baseline|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "baseline|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "baseline|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "threemonth|completed":
      return (
        <PatientCompletionStatusTableControls
          title="3-Month Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threemonth|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="3-Month Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threemonth|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="3-Month Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threemonth|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="3-Month Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threemonth|pending":
      return (
        <PatientCompletionStatusTableControls
          title="3-Month Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "oneyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="1-Year Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "oneyear|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="1-Year Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "oneyear|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="1-Year Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "oneyear|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="1-Year Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "oneyear|pending":
      return (
        <PatientCompletionStatusTableControls
          title="1-Year Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "twoyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="2-Year Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "twoyear|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="2-Year Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "twoyear|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="2-Year Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "twoyear|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="2-Year Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "twoyear|pending":
      return (
        <PatientCompletionStatusTableControls
          title="2-Year Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "threeyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="3-Year Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threeyear|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="3-Year Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threeyear|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="3-Year Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threeyear|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="3-Year Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "threeyear|pending":
      return (
        <PatientCompletionStatusTableControls
          title="3-Year Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "posttreatment|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "posttreatment|incomplete":
      return (
        <PatientCompletionStatusTableControls
          title="Post Treatment Incompleted"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "posttreatment|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="Post Treatment Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "posttreatment|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="Post Treatment Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );
    case "posttreatment|pending":
      return (
        <PatientCompletionStatusTableControls
          title="Post Treatment Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_baseline|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap Baseline Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_baseline|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap Baseline Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_baseline|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap Baseline Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_oneyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap One Year Completed"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_oneyear|not_started":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap One Year Not Started"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_oneyear|pending":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap One Year Pending"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "brocap_oneyear|withdrawn":
      return (
        <PatientCompletionStatusTableControls
          title="Brocap One Year Withdrawn"
          download={downloadTableData}
          loading={isDownloading}
          showDetail
        />
      );

    case "baselineposttreatment|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline + Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
        />
      );

    case "baselineoneyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline + Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
        />
      );

    case "baselineoneyeartwoyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline + Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
        />
      );

    case "baselineoneyeartwoyearthreeyear|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline + Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
        />
      );

    case "baselineoneyeartwoyearthreeyearposttreatment|completed":
      return (
        <PatientCompletionStatusTableControls
          title="Baseline + Post Treatment Completed"
          download={downloadTableData}
          loading={isDownloading}
        />
      );

    case "admin-settings-user-activation":
      return "User Management";

    case "admin-settings-create-user":
      return "Create User Account";

    default:
      return;
  }
};

export const renderContent = (content) => {
  switch (content) {
    case "dashboard":
      return <PieChartDashboard />;

    case "all-participants":
      return <PatientsListTable />;

    case "patient-profile":
      return <PatientProfile />;

    case "admin-settings-user-activation":
      return <AdminActivateUsers />;

    case "admin-settings-create-user":
      return <AdminCreateUser />;

    case "admin-settings-user-profile":
      return <ManageUserProfile />;

    case "baseline|completed":
      return (
        <PatientCompletionStatusTable
          key="baseline-completed"
          eventType="baseline"
          eventStatus="completed"
          parseDataFunc={parseBaselineData}
          makeMainDataColumnsFunc={makeBaselineDataColumnsFunc}
        />
      );
    case "baseline|incomplete":
      return (
        <PatientCompletionStatusTable
          key="baseline-incomplete"
          eventType="baseline"
          eventStatus="incomplete"
          parseDataFunc={parseBaselineData}
          makeMainDataColumnsFunc={makeBaselineDataColumnsFunc}
          allowExpand
        />
      );
    case "baseline|not_started":
      return (
        <PatientCompletionStatusTable
          key="baseline-not_started"
          eventType="baseline"
          eventStatus="not_started"
          parseDataFunc={parseBaselineData}
          makeMainDataColumnsFunc={makeBaselineDataColumnsFunc}
          allowExpand
        />
      );
    case "baseline|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="baseline-withdrawn"
          eventType="baseline"
          eventStatus="withdrawn"
          parseDataFunc={parseBaselineData}
          makeMainDataColumnsFunc={makeBaselineDataColumnsFunc}
        />
      );

    case "threemonth|completed":
      return (
        <PatientCompletionStatusTable
          key="threemonth-completed"
          eventType="threemonth"
          eventStatus="completed"
          parseDataFunc={parseThreeMonthData}
          makeMainDataColumnsFunc={makeThreeMonthDataColumnsFunc}
        />
      );
    case "threemonth|incomplete":
      return (
        <PatientCompletionStatusTable
          key="threemonth-incomplete"
          eventType="threemonth"
          eventStatus="incomplete"
          parseDataFunc={parseThreeMonthData}
          makeMainDataColumnsFunc={makeThreeMonthDataColumnsFunc}
          allowExpand
        />
      );
    case "threemonth|not_started":
      return (
        <PatientCompletionStatusTable
          key="threemonth-not_started"
          eventType="threemonth"
          eventStatus="not_started"
          parseDataFunc={parseThreeMonthData}
          makeMainDataColumnsFunc={makeThreeMonthDataColumnsFunc}
          allowExpand
        />
      );
    case "threemonth|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="threemonth-withdrawn"
          eventType="threemonth"
          eventStatus="withdrawn"
          parseDataFunc={parseThreeMonthData}
          makeMainDataColumnsFunc={makeThreeMonthDataColumnsFunc}
        />
      );
    case "threemonth|pending":
      return (
        <PatientCompletionStatusTable
          key="threemonth-pending"
          eventType="threemonth"
          eventStatus="pending"
          parseDataFunc={parseThreeMonthData}
          makeMainDataColumnsFunc={makeThreeMonthDataColumnsFunc}
          allowExpand
        />
      );

    case "oneyear|completed":
      return (
        <PatientCompletionStatusTable
          key="oneyear-completed"
          eventType="oneyear"
          eventStatus="completed"
          parseDataFunc={parseOneYearData}
          makeMainDataColumnsFunc={makeOneYearDataColumnsFunc}
        />
      );
    case "oneyear|incomplete":
      return (
        <PatientCompletionStatusTable
          key="oneyear-incomplete"
          eventType="oneyear"
          eventStatus="incomplete"
          parseDataFunc={parseOneYearData}
          makeMainDataColumnsFunc={makeOneYearDataColumnsFunc}
          allowExpand
        />
      );
    case "oneyear|not_started":
      return (
        <PatientCompletionStatusTable
          key="oneyear-not_started"
          eventType="oneyear"
          eventStatus="not_started"
          parseDataFunc={parseOneYearData}
          makeMainDataColumnsFunc={makeOneYearDataColumnsFunc}
          allowExpand
        />
      );
    case "oneyear|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="oneyear-withdrawn"
          eventType="oneyear"
          eventStatus="withdrawn"
          parseDataFunc={parseOneYearData}
          makeMainDataColumnsFunc={makeOneYearDataColumnsFunc}
        />
      );
    case "oneyear|pending":
      return (
        <PatientCompletionStatusTable
          key="oneyear-pending"
          eventType="oneyear"
          eventStatus="pending"
          parseDataFunc={parseOneYearData}
          makeMainDataColumnsFunc={makeOneYearDataColumnsFunc}
          allowExpand
        />
      );

    case "twoyear|completed":
      return (
        <PatientCompletionStatusTable
          key="twoyear-completed"
          eventType="twoyear"
          eventStatus="completed"
          parseDataFunc={parseTwoYearData}
          makeMainDataColumnsFunc={makeTwoYearDataColumnsFunc}
        />
      );
    case "twoyear|incomplete":
      return (
        <PatientCompletionStatusTable
          key="twoyear-incomplete"
          eventType="twoyear"
          eventStatus="incomplete"
          parseDataFunc={parseTwoYearData}
          makeMainDataColumnsFunc={makeTwoYearDataColumnsFunc}
          allowExpand
        />
      );
    case "twoyear|not_started":
      return (
        <PatientCompletionStatusTable
          key="twoyear-not_started"
          eventType="twoyear"
          eventStatus="not_started"
          parseDataFunc={parseTwoYearData}
          makeMainDataColumnsFunc={makeTwoYearDataColumnsFunc}
          allowExpand
        />
      );
    case "twoyear|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="twoyear-withdrawn"
          eventType="twoyear"
          eventStatus="withdrawn"
          parseDataFunc={parseTwoYearData}
          makeMainDataColumnsFunc={makeTwoYearDataColumnsFunc}
        />
      );
    case "twoyear|pending":
      return (
        <PatientCompletionStatusTable
          key="twoyear-pending"
          eventType="twoyear"
          eventStatus="pending"
          parseDataFunc={parseTwoYearData}
          makeMainDataColumnsFunc={makeTwoYearDataColumnsFunc}
          allowExpand
        />
      );

    case "threeyear|completed":
      return (
        <PatientCompletionStatusTable
          key="threeyear-completed"
          eventType="threeyear"
          eventStatus="completed"
          parseDataFunc={parseThreeYearData}
          makeMainDataColumnsFunc={makeThreeYearDataColumnsFunc}
        />
      );
    case "threeyear|incomplete":
      return (
        <PatientCompletionStatusTable
          key="threeyear-incomplete"
          eventType="threeyear"
          eventStatus="incomplete"
          parseDataFunc={parseThreeYearData}
          makeMainDataColumnsFunc={makeThreeYearDataColumnsFunc}
          allowExpand
        />
      );
    case "threeyear|not_started":
      return (
        <PatientCompletionStatusTable
          key="threeyear-not_started"
          eventType="threeyear"
          eventStatus="not_started"
          parseDataFunc={parseThreeYearData}
          makeMainDataColumnsFunc={makeThreeYearDataColumnsFunc}
          allowExpand
        />
      );
    case "threeyear|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="threeyear-withdrawn"
          eventType="threeyear"
          eventStatus="withdrawn"
          parseDataFunc={parseThreeYearData}
          makeMainDataColumnsFunc={makeThreeYearDataColumnsFunc}
        />
      );
    case "threeyear|pending":
      return (
        <PatientCompletionStatusTable
          key="threeyear-pending"
          eventType="threeyear"
          eventStatus="pending"
          parseDataFunc={parseThreeYearData}
          makeMainDataColumnsFunc={makeThreeYearDataColumnsFunc}
          allowExpand
        />
      );

    case "posttreatment|completed":
      return (
        <PatientCompletionStatusTable
          key="posttreatment-completed"
          eventType="posttreatment"
          eventStatus="completed"
          parseDataFunc={parsePostTreatmentData}
          makeMainDataColumnsFunc={makePostTreatmentDataColumnsFunc}
        />
      );
    case "posttreatment|incomplete":
      return (
        <PatientCompletionStatusTable
          key="posttreatment-incomplete"
          eventType="posttreatment"
          eventStatus="incomplete"
          parseDataFunc={parsePostTreatmentData}
          makeMainDataColumnsFunc={makePostTreatmentDataColumnsFunc}
          allowExpand
        />
      );
    case "posttreatment|not_started":
      return (
        <PatientCompletionStatusTable
          key="posttreatment-not_started"
          eventType="posttreatment"
          eventStatus="not_started"
          parseDataFunc={parsePostTreatmentData}
          makeMainDataColumnsFunc={makePostTreatmentDataColumnsFunc}
          allowExpand
        />
      );
    case "posttreatment|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="posttreatment-withdrawn"
          eventType="posttreatment"
          eventStatus="withdrawn"
          parseDataFunc={parsePostTreatmentData}
          makeMainDataColumnsFunc={makePostTreatmentDataColumnsFunc}
        />
      );
    case "posttreatment|pending":
      return (
        <PatientCompletionStatusTable
          key="posttreatment-pending"
          eventType="posttreatment"
          eventStatus="pending"
          parseDataFunc={parsePostTreatmentData}
          makeMainDataColumnsFunc={makePostTreatmentDataColumnsFunc}
          allowExpand
        />
      );

    case "brocap_baseline|completed":
      return (
        <PatientCompletionStatusTable
          key="brocapbaseline-completed"
          eventType="brocap_baseline"
          eventStatus="completed"
          parseDataFunc={parseBrocapBaselineData}
          makeMainDataColumnsFunc={makeBrocapBaselineDataColumnsFunc}
        />
      );

    case "brocap_baseline|not_started":
      return (
        <PatientCompletionStatusTable
          key="brocapbaseline-not_started"
          eventType="brocap_baseline"
          eventStatus="not_started"
          parseDataFunc={parseBrocapBaselineData}
          makeMainDataColumnsFunc={makeBrocapBaselineDataColumnsFunc}
          allowExpand
        />
      );

    case "brocap_baseline|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="brocapbaseline-withdrawn"
          eventType="brocap_baseline"
          eventStatus="withdrawn"
          parseDataFunc={parseBrocapBaselineData}
          makeMainDataColumnsFunc={makeBrocapBaselineDataColumnsFunc}
          allowExpand
        />
      );

    case "brocap_oneyear|completed":
      return (
        <PatientCompletionStatusTable
          key="brocaponeyear-completed"
          eventType="brocap_oneyear"
          eventStatus="completed"
          parseDataFunc={parseBrocapOneYearData}
          makeMainDataColumnsFunc={makeBrocapOneYearDataColumnsFunc}
        />
      );

    case "brocap_oneyear|not_started":
      return (
        <PatientCompletionStatusTable
          key="brocaponeyear-not_started"
          eventType="brocap_oneyear"
          eventStatus="not_started"
          parseDataFunc={parseBrocapOneYearData}
          makeMainDataColumnsFunc={makeBrocapOneYearDataColumnsFunc}
          allowExpand
        />
      );

    case "brocap_oneyear|pending":
      return (
        <PatientCompletionStatusTable
          key="brocaponeyear-pending"
          eventType="brocap_oneyear"
          eventStatus="pending"
          parseDataFunc={parseBrocapOneYearData}
          makeMainDataColumnsFunc={makeBrocapOneYearDataColumnsFunc}
          allowExpand
        />
      );

    case "brocap_oneyear|withdrawn":
      return (
        <PatientCompletionStatusTable
          key="brocaponeyear-withdrawn"
          eventType="brocap_oneyear"
          eventStatus="withdrawn"
          parseDataFunc={parseBrocapOneYearData}
          makeMainDataColumnsFunc={makeBrocapOneYearDataColumnsFunc}
          allowExpand
        />
      );

    case "baselineposttreatment|completed":
      return (
        <PatientCompletionStatusTable
          key="baselineposttreatment-completed"
          eventType="baselineposttreatment"
          eventStatus="completed"
          parseDataFunc={parseData}
          makeMainDataColumnsFunc={makeDataColumnsFunc}
        />
      );

    case "baselineoneyear|completed":
      return (
        <PatientCompletionStatusTable
          key="baselineoneyear-completed"
          eventType="baselineoneyear"
          eventStatus="completed"
          parseDataFunc={parseData}
          makeMainDataColumnsFunc={makeDataColumnsFunc}
        />
      );

    case "baselineoneyeartwoyear|completed":
      return (
        <PatientCompletionStatusTable
          key="baselineoneyeartwoyear-completed"
          eventType="baselineoneyeartwoyear"
          eventStatus="completed"
          parseDataFunc={parseData}
          makeMainDataColumnsFunc={makeDataColumnsFunc}
        />
      );

    case "baselineoneyeartwoyearthreeyear|completed":
      return (
        <PatientCompletionStatusTable
          key="baselineoneyeartwoyearthreeyear-completed"
          eventType="baselineoneyeartwoyearthreeyear"
          eventStatus="completed"
          parseDataFunc={parseData}
          makeMainDataColumnsFunc={makeDataColumnsFunc}
        />
      );

    case "baselineoneyeartwoyearthreeyearposttreatment|completed":
      return (
        <PatientCompletionStatusTable
          key="baselineoneyeartwoyearthreeyearposttreatment-completed"
          eventType="baselineoneyeartwoyearthreeyearposttreatment"
          eventStatus="completed"
          parseDataFunc={parseData}
          makeMainDataColumnsFunc={makeDataColumnsFunc}
        />
      );

    case "user-change-password":
      return <ChangePassword />;

    case "logoff":
      return "Logging off...";

    default:
      return <p>Error encountered, please reload.</p>;
  }
};
