import React, { useState } from "react";
import moment from "moment";
import * as APIUtility from "../../Util/api";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Descriptions, Badge, Form, Switch, DatePicker, Row, Col } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { COLORS } from "../PieChartDashboard/PieConstants";
import { useAlert } from "react-alert";
const lodash = require("lodash");
const dateFormat = "YYYY-MM-DD";

const PatientProfile = (props) => {
  const alert = useAlert();
  const [studyExitEditDisabled, setStudyExitEditDisabled] = useState(true);

  const patientId = lodash.get(props.profileData, "patient_id") || "";
  const enrollmentDate = lodash.get(props.profileData, "enrollment_date");
  const brocapConsentDate = lodash.get(props.profileData, "brocap_consent_date");
  const siteName = lodash.get(props.profileData, "site_name");
  const rubyGroup = lodash.get(props.profileData, "ruby_group");
  const consentSurveys = lodash.get(props.profileData, "consent_descript") === "Yes" ? "Yes" : "No";
  const consentBlood = lodash.get(props.profileData, "consent_blood_descript") === "Yes" ? "Yes" : "No";
  const consentTissue = lodash.get(props.profileData, "consent_tissue_descript") === "Yes" ? "Yes" : "No";
  const consentFutureStudies =
    lodash.get(props.profileData, "consent_future_studies_descript") === "Yes" ? "Yes" : "No";
  const consentFutureGenetics =
    lodash.get(props.profileData, "consent_future_genetics_descript") === "Yes" ? "Yes" : "No";
  const consentBrocap = lodash.get(props.profileData, "consent_brocap_descript") === "Yes" ? "Yes" : "No";

  const dateDeceased = lodash.get(props.profileData, "deceased_date")
    ? moment(lodash.get(props.profileData, "deceased_date"), dateFormat)
    : null;

  const dateWithdrawnAll = lodash.get(props.profileData, "withdrawn_all_date")
    ? moment(lodash.get(props.profileData, "withdrawn_all_date"), dateFormat)
    : null;

  const dateLostFollowUp = lodash.get(props.profileData, "lost_follow_up_date")
    ? moment(lodash.get(props.profileData, "lost_follow_up_date"), dateFormat)
    : null;

  const dateWithdrawnSurveys = lodash.get(props.profileData, "withdrawn_surveys_date")
    ? moment(lodash.get(props.profileData, "withdrawn_surveys_date"), dateFormat)
    : null;

  const dateWithdrawnBlood = lodash.get(props.profileData, "withdrawn_blood_date")
    ? moment(lodash.get(props.profileData, "withdrawn_blood_date"), dateFormat)
    : null;

  const dateWithdrawnTissue = lodash.get(props.profileData, "withdrawn_tissue_date")
    ? moment(lodash.get(props.profileData, "withdrawn_tissue_date"), dateFormat)
    : null;

  const renderSurveyStatusBadge = (status) => {
    if (props.profileData === null) {
      return null;
    }

    if (status === "Completed" || status === "Complete" || status === "Completed, Withdrawn") {
      return <Badge color={COLORS.Completed} text={status} />;
    } else if (status === "Incomplete") {
      return <Badge color={COLORS.Incomplete} text={status} />;
    } else if (status === "Not Started") {
      return <Badge color={COLORS["Not started"]} text={status} />;
    } else if (status === "Withdrawn") {
      return <Badge color={COLORS.Withdrawn} text={status} />;
    } else if (status === "Pending") {
      return <Badge color={COLORS.Pending} text={status} />;
    } else {
      return <Badge color={COLORS.Pending} text={status} />;
    }
  };

  const renderFfqStatusBadge = () => {
    if (props.profileData === null) {
      return null;
    }

    const ffqCompleteDate = lodash.get(props.profileData, "baseline_completed_events.ffq");

    if (ffqCompleteDate === undefined) {
      return <Badge color={COLORS.Incomplete} text="Incomplete" />;
    } else {
      return <Badge color={COLORS.Completed} text="Completed" />;
    }
  };

  const renderGiftPreferenceBadge = () => {
    if (props.profileData === null) {
      return null;
    }

    const giftCompleteDate = lodash.get(props.profileData, "baseline_completed_events.gift_card_preference_form");

    if (giftCompleteDate === undefined) {
      return <Badge color={COLORS.Incomplete} text="Incomplete" />;
    } else {
      return <Badge color={COLORS.Completed} text="Completed" />;
    }
  };

  const handleDatePickerChange = (dateObj, dateString, dataMember) => {
    const updateSuccess = updateDateValue(dateObj, dateString, dataMember);
  };

  const updateDateValue = (dateObj, dateString, dataMember) => {
    const paramString = "?id=" + patientId;

    const data = { [dataMember]: dateString };

    APIUtility.API.makeAPICall(APIUtility.GET_PATIENT_PROFILE, paramString, data).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
      } else if (response.status !== 200) {
        response.text().then((text) => alert.show(text));
        return false;
      } else {
        response.json().then((data) => {
          props.setPatientProfile(data);
        });
        return true;
      }
    });
  };

  const toggleStudyExitEdit = (bool) => {
    if (!props.userReadOnly) {
      setStudyExitEditDisabled(bool);
    } else {
      alert.show("You have read-only permission to this participant file.");
    }
  };

  return (
    <React.Fragment>
      <Descriptions
        title={"Participant ID: " + patientId}
        patientId
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Date of consent">{enrollmentDate}</Descriptions.Item>
        <Descriptions.Item label="Ruby Group">{rubyGroup}</Descriptions.Item>
        <Descriptions.Item label="Site">{siteName}</Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title="Optional Consent Items" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">{consentSurveys}</Descriptions.Item>
        <Descriptions.Item label="Blood">{consentBlood}</Descriptions.Item>
        <Descriptions.Item label="Tissue">{consentTissue}</Descriptions.Item>
        <Descriptions.Item label="Future contact">{consentFutureStudies}</Descriptions.Item>
        <Descriptions.Item label="Genetics">{consentFutureGenetics}</Descriptions.Item>
        <Descriptions.Item label="BroCap Consent">{consentBrocap}</Descriptions.Item>
        <Descriptions.Item label="BroCap Consent Date">{brocapConsentDate}</Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions
        title={
          <React.Fragment>
            Study Exits
            <Switch
              unCheckedChildren={<UnlockOutlined />}
              checkedChildren={<LockOutlined />}
              checked={studyExitEditDisabled}
              onChange={toggleStudyExitEdit}
              style={{ marginLeft: 16, backgroundColor: studyExitEditDisabled ? "grey" : "red" }}
              size="small"
            />
          </React.Fragment>
        }
      />

      <Form name="study-exit">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Deceased">
              <DatePicker
                value={dateDeceased}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "deceased_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Withdrawn All">
              <DatePicker
                value={dateWithdrawnAll}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "withdrawn_all_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Lost to Follow-up">
              <DatePicker
                value={dateLostFollowUp}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "lost_follow_up_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Withdrawn Surveys">
              <DatePicker
                value={dateWithdrawnSurveys}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "withdrawn_surveys_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Withdrawn Blood">
              <DatePicker
                value={dateWithdrawnBlood}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "withdrawn_blood_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Withdrawn Tissue">
              <DatePicker
                value={dateWithdrawnTissue}
                format={dateFormat}
                onChange={(date, dateStr) => handleDatePickerChange(date, dateStr, "withdrawn_tissue_date")}
                disabled={props.userReadOnly || studyExitEditDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Descriptions title="Baseline" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "baseline_status"))}
        </Descriptions.Item>
        <Descriptions.Item label="FFQ">{renderFfqStatusBadge()}</Descriptions.Item>
        <Descriptions.Item label="Giftcard Preference">{renderGiftPreferenceBadge()}</Descriptions.Item>
        <Descriptions.Item label="Blood Arrival at WCH">
          {lodash.get(props.profileData, "dateSampleReceived")}
        </Descriptions.Item>
        <Descriptions.Item label="Tissue Arrival at WCH">
          {lodash.get(props.profileData, "dateSampleReceivedTissue")}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Descriptions title="3 Month" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "threemonth_status"))}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Descriptions title="1 Year" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "oneyear_status"))}
        </Descriptions.Item>
        <Descriptions.Item label="Blood Arrival at WCH">
          {lodash.get(props.profileData, "dateSampleReceivedOneYear")}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Descriptions title="Post Treatment" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "posttreatment_status"))}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Descriptions title="2 Year" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "twoyear_status"))}
        </Descriptions.Item>
        <Descriptions.Item label="Blood Arrival at WCH">
          {lodash.get(props.profileData, "dateSampleReceivedTwoYear")}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <Descriptions title="3 Year" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="Surveys">
          {renderSurveyStatusBadge(lodash.get(props.profileData, "threeyear_status"))}
        </Descriptions.Item>
        <Descriptions.Item label="Blood Arrival at WCH">
          {lodash.get(props.profileData, "dateSampleReceivedThreeYear")}
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.patientProfile.profileData,
    userReadOnly: state.authentication.userReadOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPatientProfile: (data) => dispatch(actions.setPatientProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
