import store from "../Store/configureStore";
import * as actions from "../Store/Actions/index";
import { urlJoinPath } from "../Util/url";
// Endpoints
export const GET_TOKEN = "GET_TOKEN";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const LOG_OFF = "LOG_OFF";

export const GET_SIGN_UP_SITE_OPTIONS = "GET_SIGN_UP_SITE_OPTIONS";
export const IS_SERVER_RUNNING = "IS_SERVER_RUNNING";
export const CREATE_USER = "CREATE_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const SURVEY_COMPLETION_STATUS = "SURVEY_COMPLETION_STATUS";
export const GET_PATIENTS_LIST = "GET_PATIENTS_LIST";
export const GET_PATIENTS_LIST_CSV = "GET_PATIENTS_LIST_CSV";
export const GET_PATIENT_EVENT_STATUS = "GET_PATIENT_EVENT_STATUS";
export const GET_PATIENT_EVENT_STATUS_CSV = "GET_PATIENT_EVENT_STATUS_CSV";
export const GET_PATIENT_PROFILE = "GET_PATIENT_PROFILE";

export const GET_USER_LIST = "GET_USER_LIST";
export const ADMIN_ACTIVATE_DEACTIVATE_USERS = "ADMIN_ACTIVATE_DEACTIVATE_USERS";
export const ADMIN_UPDATE_USER_SITES = "ADMIN_UPDATE_USER_SITES";
export const ADMIN_CREATE_USER = "ADMIN_CREATE_USER";
export const ADMIN_DELETE_USER_ACCOUNT = "ADMIN_DELETE_USER_ACCOUNT";
export const ADMIN_RESET_USER_PASSWORD = "ADMIN_RESET_USER_PASSWORD";
export const ADMIN_UPDATE_USER_PROFILE = "ADMIN_UPDATE_USER_PROFILE";

export const GET_DATA_SUMMARY_OPTIONS = "GET_DATA_SUMMARY_OPTIONS";
export const GET_DATA_SUMMARY = "GET_DATA_SUMMARY";
export const GET_DATA_DICTIONARY = "GET_DATA_DICTIONARY";
export const GET_SURVEY_EVENT_TABLE = "GET_SURVEY_EVENT_TABLE";
export const GET_DATA_SUMMARY_SANKEY = "GET_DATA_SUMMARY_SANKEY";

export const GET_DATA_CARE_PATHWAY = "GET_DATA_CARE_PATHWAY";
export const UPDATE_DATA_CARE_PATHWAY = "UPDATE_DATA_CARE_PATHWAY";

export class API {
  static serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  static urlBeginning = this.serverAddress;
  static authUrlBeginning = this.serverAddress + "/o/";
  static json = "/?format=json";

  /**
   * Retrieves the saved token from the local storage. If the local storage does not
   * contain the token, returns -1
   */
  static getTokenFromLS() {
    const localStorageToken = localStorage.getItem("tokenObject");
    if (localStorageToken !== "" && localStorageToken !== null) {
      return JSON.parse(localStorageToken).access_token;
    } else {
      return -1;
    }
  }

  /**
   * Used to verify the validity of the token stored in local storage
   */
  static verifyLSToken = (callBackFunction) => {
    this.makeAPICall(VALIDATE_TOKEN)
      .then((response) => {
        if (response.status === 200) {
          store.dispatch(actions.setIsAuthorized(true));
          response.json().then((data) => {
            store.dispatch(actions.setUserRole(data.role));
            store.dispatch(actions.setUserReadOnly(data.readOnly));
          });
        }

        if (callBackFunction) {
          callBackFunction();
        }
      })
      .catch((err) => {
        if (callBackFunction) {
          callBackFunction();
        }
      });
  };

  /**
   * Used to request a token from the backend server and handle the response
   */
  static getTokenFromAPI(username, password) {
    const data = {
      username: username,
      password: password,
      grant_type: "password",
    };
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    return this._handleGetTokenAPICall(data);
  }

  /**
   * Helper method used to make the get token API call and handle the response
   */
  static _handleGetTokenAPICall(data) {
    return this.makeAPICall(GET_TOKEN, null, data).then((response) => {
      if (!response) {
        store.dispatch(actions.setIsServerDown(true));
      } else {
        response
          .clone()
          .json()
          .then((response) => {
            if (response.access_token !== undefined) {
              localStorage.setItem("tokenObject", JSON.stringify(response));
              store.dispatch(actions.setUserRole(response.user.role));
              store.dispatch(actions.setIsAuthorized(true));
              store.dispatch(actions.setUserChangePassword(response.user.changePassword));
              store.dispatch(actions.setIsServerDown(false));
            }
          });
        return response;
      }
    });
  }

  /**
   * Method used to add the authorization token from the local storage before making
   * the API call.
   */
  static _addAuthorization(url, requestType = "GET", data) {
    const oAuthToken = this.getTokenFromLS();
    let bearer = "Bearer " + oAuthToken;

    const options = { method: requestType };

    if (requestType !== "GET" && data) {
      options.body = data;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", bearer);

    options.headers = headers;

    return this._fetchFromAPI(url, options);
  }

  /**
   * Method used to remove the user related store values from the store
   */
  static revokeUserAuthorizationFromStore() {
    //TODO: Move re-initialization methods to each reducer
    store.dispatch(actions.setIsAuthorized(false));
    store.dispatch(actions.setUserRole(null));
    store.dispatch(actions.setSiteSelected([]));
    store.dispatch(actions.setPatientProfile(null));
    store.dispatch(actions.setActiveContent(["dashboard"]));
    store.dispatch(actions.setSiteOptions(null));
    store.dispatch(actions.setDataCompStatus(null));
    store.dispatch(actions.setPatientsListTableData([]));
    store.dispatch(actions.setDataSummary(null));
  }

  /**
   * This method makes the API call and returns the API response as promise
   * If the token is expired, updates the corresponding flag in the store
   * If the server is not responding, updates the corresponding flag in the store
   */
  static _fetchFromAPI(url, options = {}) {
    if (options.headers === undefined) {
      options.headers = {};
    }
    options.headers["Content-Type"] = "application/json";
    if (options.body !== undefined) {
      options.body = JSON.stringify(options.body);
    }

    return fetch(url, options)
      .then((response) => {
        if (response && response.status === 401) {
          this.revokeUserAuthorizationFromStore();
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static makeAPICall(endpoint, urlParam = "", data) {
    const options = { method: "GET", body: data };
    let path = null;
    let requestType = "GET";

    switch (endpoint) {
      case GET_TOKEN:
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        options.body.client_id = process.env.REACT_APP_CLIENT_ID;
        options.method = "POST";
        options.headers = headers;
        path = urlJoinPath(this.urlBeginning, "/users/login/");
        return this._fetchFromAPI(path, options);
        break;

      case GET_SIGN_UP_SITE_OPTIONS:
        path = urlJoinPath(this.urlBeginning, "/sitesettings/");
        return this._fetchFromAPI(path);
        break;

      case IS_SERVER_RUNNING:
        path = urlJoinPath(this.urlBeginning, "isserverrunning/");
        this._fetchFromAPI(path).then((response) => {
          if (!response) {
            store.dispatch(actions.setIsServerDown(true));
          }
          return response;
        });
        break;

      case CREATE_USER:
        options.method = "POST";
        path = urlJoinPath(this.urlBeginning, "/users/createuser/");
        return this._fetchFromAPI(path, options);

      case VALIDATE_TOKEN:
        path = urlJoinPath(this.urlBeginning, "/users/validateToken/");
        return this._addAuthorization(path);

      case SURVEY_COMPLETION_STATUS:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/completionStatus/", urlParam);
        break;
      case GET_PATIENT_EVENT_STATUS:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/reports/", urlParam);
        break;
      case GET_PATIENT_EVENT_STATUS_CSV:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/csv/", urlParam);
        break;
      case GET_PATIENT_PROFILE:
        if (data) {
          requestType = "PUT";
        }
        path = urlJoinPath(this.urlBeginning, "/consenttracker/participant/", urlParam);
        break;
      case GET_PATIENTS_LIST:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/reports/allparticipants/", urlParam);
        break;

      case GET_PATIENTS_LIST_CSV:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/csv/allparticipants/", urlParam);
        break;

      case GET_SURVEY_EVENT_TABLE:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/surveyEventTable/", urlParam);
        this._addAuthorization(path, requestType, data).then((response) => {
          if (response) {
            response.json().then((data) => {
              store.dispatch(actions.setSurveyEventTalbe(data));
            });
          }
        });
        break;

      case GET_DATA_CARE_PATHWAY:
        path = urlJoinPath(this.urlBeginning, "/carepathway/getData/", urlParam);
        this._addAuthorization(path, requestType, data).then((response) => {
          if (response) {
            response.json().then((data) => {
              store.dispatch(actions.setDataCarePathway(data));
            });
          }
        });
        break;

      case UPDATE_DATA_CARE_PATHWAY:
        path = urlJoinPath(this.urlBeginning, "/carepathway/updateData/", urlParam);
        this._addAuthorization(path, requestType, data).then((response) => {
          if (response) {
            response.json().then((data) => {
              console.log(data);
            });
          }
        });
        break;

      case GET_DATA_SUMMARY_SANKEY:
        path = urlJoinPath(this.urlBeginning, "/consenttracker/dataSummarySankey/", urlParam);
        this._addAuthorization(path, requestType, data).then((response) => {
          if (response) {
            response.json().then((data) => {
              store.dispatch(actions.setDataSummarySankey(data));
            });
          }
        });
        break;

      case GET_DATA_SUMMARY_OPTIONS:
        requestType = "GET";
        path = urlJoinPath(this.urlBeginning, "/consenttracker/datadistribution/options/", urlParam);
        break;

      case GET_DATA_SUMMARY:
        requestType = "GET";
        path = urlJoinPath(this.urlBeginning, "/consenttracker/datadistribution/", urlParam);
        break;

      case GET_DATA_DICTIONARY:
        requestType = "GET";
        path = urlJoinPath(this.urlBeginning, "/consenttracker/datadictionary/", urlParam);
        break;

      case GET_USER_LIST:
        requestType = "POST";
        path = urlJoinPath(this.urlBeginning, "/users/admin/userlist/");
        break;

      case ADMIN_ACTIVATE_DEACTIVATE_USERS:
        requestType = "PUT";
        path = urlJoinPath(this.urlBeginning, "/users/admin/updateuserisactive/");
        break;

      case ADMIN_UPDATE_USER_SITES:
        requestType = "PUT";
        path = urlJoinPath(this.urlBeginning, "/users/admin/updateusersites/");
        break;

      case ADMIN_DELETE_USER_ACCOUNT:
        requestType = "DELETE";
        path = urlJoinPath(this.urlBeginning, "/users/admin/deleteuser/", urlParam);
        break;

      case ADMIN_RESET_USER_PASSWORD:
        requestType = "PUT";
        path = urlJoinPath(this.urlBeginning, "/users/admin/resetpassword/", urlParam);
        break;

      case ADMIN_CREATE_USER:
        requestType = "POST";
        path = urlJoinPath(this.urlBeginning, "/users/admin/createuser/");
        break;

      case ADMIN_UPDATE_USER_PROFILE:
        requestType = "PUT";
        path = urlJoinPath(this.urlBeginning, "/users/admin/updateuser/");
        break;

      case CHANGE_PASSWORD:
        requestType = "PUT";
        path = urlJoinPath(this.urlBeginning, "/users/changepassword/");
        break;

      case LOG_OFF:
        requestType = "POST";
        path = urlJoinPath(this.urlBeginning, "/users/logoff/");
        break;

      default:
        break;
    }
    return this._addAuthorization(path, requestType, data);
  }
}
