import * as actionTypes from "./actionTypes";

export const toggleShowAdditionalColumns = () => {
  return {
    type: actionTypes.TOGGLE_SHOW_ADDITIONAL_COLUMNS,
  };
};

export const setPatientCompletionTableData = (data) => {
  return {
    type: actionTypes.SET_PATIENT_COMPLETION_TABLE_DATA,
    data,
  };
};
