const lodash = require("lodash");

export const defaultPaginationSettings = { page: 1, pageSize: 20 };
export const defaultSorterSettings = { order: "ascend", orderBy: "id" };

export const makePaginationRequestParams = (paginationSettings, sorterSettings, filterSettings, prefix = "") => {
  const page = lodash.get(paginationSettings, "page") || defaultPaginationSettings.page;
  const pageSize = lodash.get(paginationSettings, "pageSize") || defaultPaginationSettings.pageSize;
  const sortOrder = lodash.get(sorterSettings, "order") || defaultSorterSettings.order;
  const orderByProperty = lodash.get(sorterSettings, "orderBy") || defaultSorterSettings.orderBy;

  let filterString = "";

  if (filterSettings) {
    for (let [filterColumn, filterValues] of Object.entries(filterSettings)) {
      if (filterValues && filterValues.length > 0) {
        filterValues.forEach((filterValue) => {
          filterString += "&" + filterColumn + "=" + filterValue;
        });
      }
    }
  }

  return (
    prefix +
    "?page=" +
    page +
    "&size=" +
    pageSize +
    "&orderBy=" +
    orderByProperty +
    "&order=" +
    sortOrder +
    filterString
  );
};
