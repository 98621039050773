import React, { useState } from "react";
import * as APIUtility from "../../Util/api";
import { connect } from "react-redux";
import { Input, Tooltip, Select, Button, Checkbox } from "antd";
import { CheckOutlined, SendOutlined, CopyOutlined, UserAddOutlined } from "@ant-design/icons";
import { isValidEmail } from "../../Util/inputValidation";
import { useAlert } from "react-alert";

const lodash = require("lodash");
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CreateUserAccount = (props) => {
  const alert = useAlert();

  const [accounts, setAccounts] = useState({});

  const addRow = () => {
    const accountDefaults = {
      username: "",
      email: "",
      fullname: "",
      site: "",
      readOnly: false,
      password: "",
      sent: false,
      created: false,
    };

    if (!accounts || Object.entries(accounts).length === 0) {
      setAccounts({ 0: accountDefaults });
    } else {
      const accountData = { ...accounts, [Object.entries(accounts).length]: accountDefaults };
      setAccounts(accountData);
    }
  };

  const getAccountAttribute = (row, attribute) => {
    return lodash.get(accounts, `${row}.${attribute}`);
  };

  const submit = (e) => {
    const row = e.currentTarget.getAttribute("row");

    const username = getAccountAttribute(row, "username");
    const fullname = getAccountAttribute(row, "fullname");
    const email = getAccountAttribute(row, "email");
    const site = getAccountAttribute(row, "site");
    const readOnly = getAccountAttribute(row, "readOnly");

    if (!username) return alert.show("Please enter username.");
    if (!fullname) return alert.show("Please enter full name.");
    if (!email) return alert.show("Please enter email address.");
    if (!site) return alert.show("Please select a site.");

    if (!isValidEmail(username)) return alert.show("Please enter a valid username.");
    if (!isValidEmail(email)) return alert.show("Please enter a valid email address.");

    const data = { email, username, fullname, site, readOnly };

    setAccountAttribute(row, "sent", true);
    APIUtility.API.makeAPICall(APIUtility.ADMIN_CREATE_USER, null, data).then((response) => {
      if (!response) {
        alert.show("Server busy, please try again.");
        setAccountAttribute(row, "sent", false);
      } else if (response.status === 200) {
        alert.show("Account created successfully.");
        response.json().then((result) => {
          setAccountAttribute(row, "password", result.password);
        });
        setAccountAttribute(row, "created", true);
        setAccountAttribute(row, "sent", false);
      } else {
        response.json().then((result) => {
          alert.show(result.message);
          setAccountAttribute(row, "sent", false);
        });
      }
    });
  };

  const setAccountAttribute = (row, attribute, value) => {
    const accountData = { ...accounts };
    lodash.set(accountData, `${row}.${attribute}`, value);
    setAccounts(accountData);
  };

  const onInputChange = (e) => {
    const inputFieldId = e.target.id || e.target.name;
    const inputValue = e.target.value;
    const row = e.target.getAttribute("row");

    setAccountAttribute(row, inputFieldId, inputValue);
  };

  const onSelectChange = (row, value) => {
    setAccountAttribute(row, "site", value);
  };

  const onCheckboxChange = (e) => {
    const row = e.target.row;
    const checked = e.target.checked;
    setAccountAttribute(row, "readOnly", checked);
  };

  const copyToClipboard = (e) => {
    const row = e.currentTarget.getAttribute("row");

    const username = getAccountAttribute(row, "username");
    const password = getAccountAttribute(row, "password");
    const email = getAccountAttribute(row, "email");

    const clipText = `username: ${username}, email: ${email}, password: ${password}`;
    const dummyDiv = document.createElement("textarea");
    document.body.appendChild(dummyDiv);
    dummyDiv.value = clipText;
    dummyDiv.select();
    document.execCommand("copy");
    document.body.removeChild(dummyDiv);
  };

  const copyAllToClipboard = (e) => {
    let clipText = "username,password,email \n";

    Object.entries(accounts).forEach(([row, data]) => {
      if (lodash.get(data, "created")) {
        clipText +=
          lodash.get(data, "username") + "," + lodash.get(data, "password") + "," + lodash.get(data, "email") + "\n";
      }
    });

    const dummyDiv = document.createElement("textarea");
    document.body.appendChild(dummyDiv);
    dummyDiv.value = clipText;
    dummyDiv.select();
    document.execCommand("copy");
    document.body.removeChild(dummyDiv);
  };

  const makeSiteSelectDropDown = () => {
    if (!props.siteOptions) {
      return;
    }

    return Object.entries(props.siteOptions).map(([site, siteName]) => (
      <Option value={site} key={site}>
        {siteName}
      </Option>
    ));
  };

  const makeRow = (row) => {
    const sent = getAccountAttribute(row, "sent");
    const created = getAccountAttribute(row, "created");

    return (
      <div key={row}>
        <Tooltip title="Username of the account to be created.">
          <Input
            style={{ width: "15%" }}
            placeholder="Username"
            id={"username"}
            row={row}
            disabled={sent || created}
            onChange={onInputChange}
          />
        </Tooltip>
        <Tooltip title="Full name of the person this account is created for.">
          <Input
            style={{ width: "15%" }}
            placeholder="Full name"
            id={"fullname"}
            row={row}
            disabled={sent || created}
            onChange={onInputChange}
          />
        </Tooltip>
        <Tooltip title="Email address associated with the account to be created.">
          <Input
            style={{ width: "20%" }}
            placeholder="Email"
            id={"email"}
            row={row}
            disabled={sent || created}
            onChange={onInputChange}
          />
        </Tooltip>
        <Tooltip title="A temporary password will be generated automatically when the account is being created. User can log in with this temporary password and will be asked to set up a new password. You will not be able to see this password in plain text again once this page is closed.">
          <div style={{ width: "18%" }} className="ant-input">
            <Input
              style={{ pointerEvents: "none", border: "none", margin: 0, padding: 0 }}
              placeholder="Temporary password"
              id={"password"}
              row={row}
              value={getAccountAttribute(row, "password")}
              disabled={true}
            />
          </div>
        </Tooltip>
        <Tooltip title="Select a site that the user will be to able to view, select 'All' if the user needs to view all sites.">
          <Select
            style={{ width: "14%" }}
            defaultValue=""
            placeholder="Site"
            id="site"
            row={row}
            disabled={sent || created}
            onChange={(value) => onSelectChange(row, value)}
          >
            <Option value="all">All</Option>
            {makeSiteSelectDropDown()}
          </Select>
        </Tooltip>
        <Tooltip title="Check this box to prevent this user from editing participant profile.">
          <div className="ant-btn">
            <Checkbox onChange={onCheckboxChange} disabled={sent || created} id="readOnly" row={row}>
              Read-only
            </Checkbox>
          </div>
        </Tooltip>
        <Tooltip title={created ? "User account has been created." : "Click to create user account."}>
          <Button
            icon={created ? <CheckOutlined /> : <SendOutlined />}
            onClick={submit}
            row={row}
            loading={sent}
            disabled={created}
          />
        </Tooltip>
        {created ? (
          <Tooltip title="Copy this user's account credential to clipboard.">
            <Button icon={<CopyOutlined />} onClick={copyToClipboard} row={row}></Button>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const makeRows = () => {
    return Object.entries(accounts).map(([row, data]) => makeRow(row));
  };

  return (
    <React.Fragment>
      You must copy and save the user credentials created here before leaving this page. You will not be able to see the
      temporary passwords created here again. Please send the temporary log-in credentials to the user via secure means.
      The users will be asked to set up a new password when they log in.
      <br />
      {makeRows()}
      <Tooltip title="Add another user account.">
        <Button icon={<UserAddOutlined />} onClick={addRow} />
      </Tooltip>
      <Tooltip title="Copy all user account credentials to clipboard.">
        <Button icon={<CopyOutlined />} onClick={copyAllToClipboard} />
      </Tooltip>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    siteOptions: state.studyCompletionStatus.siteOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserAccount);
