import React, { useState } from "react";
import * as APIUtility from "../../Util/api";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Popover, Button, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { PARSE_SET_CHART_DATA } from "./PieConstants";

const lodash = require("lodash");

const SiteSelector = (props) => {
  const [controlDisabled, setControlDisabled] = useState(false);

  const sites = lodash.get(props, "siteOptions");

  const siteOptions = sites
    ? Object.entries(sites).map(([value, name]) => {
        return { label: name, value };
      })
    : [];

  const siteOptionValues = sites ? Object.entries(sites).map(([key, value]) => key) : [];

  const updateChartData = ({ selectedValues = [], selectedAll = false }) => {
    // if nothing is selected
    if (selectedValues.length === 0 && selectedAll === false) {
      PARSE_SET_CHART_DATA([]);
      return;
    }

    let urlParamString = "?";

    if (selectedAll) {
      urlParamString = "";
    } else {
      selectedValues.forEach((element, i) => {
        urlParamString += "site_id=" + element;
        if (i < selectedValues.length - 1) {
          urlParamString += "&";
        }
      });
    }

    APIUtility.API.makeAPICall(APIUtility.SURVEY_COMPLETION_STATUS, urlParamString)
      .then((response) => response.json())
      .then((data) => {
        PARSE_SET_CHART_DATA(data);
        setControlDisabled(false);
      });
  };

  const onSelectChange = (selectedValues) => {
    setControlDisabled(true);
    props.setSelected(selectedValues);
    updateChartData({ selectedValues });
  };

  const onSelectAllChange = (e) => {
    if (lodash.isEqual(siteOptionValues, props.selectedSites)) {
      props.setSelected([]);
      updateChartData({ selectedAll: false });
    } else {
      props.setSelected(siteOptionValues);
      updateChartData({ selectedAll: true });
    }
  };

  const checkAllIndeterminate = () => {
    if (siteOptionValues.length !== props.selectedSites.length && props.selectedSites.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAllChecked = lodash.isEqual(siteOptionValues, props.selectedSites);

  const makeMenu = () => {
    return (
      <React.Fragment>
        <Checkbox
          onChange={onSelectAllChange}
          indeterminate={checkAllIndeterminate()}
          checked={checkAllChecked}
          disabled={controlDisabled}
        >
          All
        </Checkbox>
        <Checkbox.Group
          options={siteOptions}
          onChange={onSelectChange}
          value={props.selectedSites}
          disabled={controlDisabled}
        />
      </React.Fragment>
    );
  };

  return (
    <Popover content={makeMenu()} trigger="hover">
      <Button icon={<FilterOutlined />} />
    </Popover>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.studyCompletionStatus.data,
    selectedSites: state.studyCompletionStatus.selectedSites,
    siteOptions: state.studyCompletionStatus.siteOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelected: (selected) => dispatch(actions.setSiteSelected(selected)),
    setData: (data) => dispatch(actions.setDataCompStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelector);
