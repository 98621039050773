export const parseThreeMonthData = (data) => {
  data.forEach((item) => {
    Object.entries(item["threemonth_completed_events"]).forEach(([key, value]) => {
      item[key] = value;
    });
  });
  return data;
};

/**
 * Method that returns a list of table columns
 * @param {boolean} anchorLeftColumnBool Boolean value to indicate whether to anchor some columns to the left.
 * @param {function} getColumnSearchProps Function used to search column in table.
 * @param {boolean} showMoreTableColumns Boolean value to indicate whether to show/hide some columns
 */
export const makeThreeMonthDataColumnsFunc = (anchorLeftColumnBool, getColumnSearchProps, showMoreTableColumns) => {
  if (showMoreTableColumns) {
    return [...fixedColumns(anchorLeftColumnBool, getColumnSearchProps), ...additionalColumns];
  } else {
    return fixedColumns(false, getColumnSearchProps);
  }
};

const fixedColumns = (anchorLeftColumnBool, getColumnSearchProps) => [
  {
    title: "Patient Id",
    dataIndex: "patient_id",
    key: "patient_id",
    width: 120,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.id, b.id),
    // ...getColumnSearchProps("id")
  },
  {
    title: "Site",
    dataIndex: "site_name",
    key: "site_name",
    width: 140,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.site, b.site),
    // ...getColumnSearchProps("site_name")
  },
  // {
  //   title: "Consent",
  //   dataIndex: "consent_descript",
  //   key: "consent_descript",
  //   width: 95,
  //   sorter: (a, b) => sortString(a.consent, b.consent),
  // },
  {
    title: "Enrollment",
    dataIndex: "enrollment_date",
    key: "enrollment_date",
    width: 120,
  },
  {
    title: "Group",
    dataIndex: "ruby_group",
    key: "ruby_group",
    width: 80,
    sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  },
  // {
  //   title: "Gift Status",
  //   dataIndex: "gift_card_status",
  //   key: "gift_card_status",
  //   width: 100,
  //   sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  // },
  {
    title: "Outstanding Surveys",
    dataIndex: "num_outstanding_surveys",
    key: "num_outstanding_surveys",
    width: 80,
    filters: [
      { text: ">=1", value: "gte:1" },
      { text: ">=2", value: "gte:2" },
      { text: ">=3", value: "gte:3" },
      { text: ">=5", value: "gte:5" },
      { text: ">=10", value: "gte:10" },
      { text: ">=15", value: "gte:15" },
      { text: ">=20", value: "gte:20" },
      { text: ">=25", value: "gte:25" },
    ],
    filterMultiple: false,
  },
  {
    title: "Pass 90 Day Window",
    dataIndex: "pass_reminder_window",
    key: "pass_reminder_window",
    width: 80,
    filters: [
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
    ],
    filterMultiple: false,
  },
  // {
  //   title: "Days in Current Event",
  //   dataIndex: "days_in_cur_event",
  //   key: "days_in_cur_event",
  //   width: 80,
  //   filters: [
  //     { text: ">7", value: "gt:7" },
  //     { text: ">14", value: "gt:14" },
  //     { text: ">30", value: "gt:30" },
  //     { text: ">45", value: "gt:45" },
  //     { text: ">60", value: "gt:60" },
  //     { text: ">90", value: "gt:90" },
  //     { text: ">120", value: "gt:120" },
  //     { text: ">180", value: "gt:180" },
  //     { text: ">275", value: "gt:275" },
  //     { text: ">365", value: "gt:365" },
  //     { text: ">500", value: "gt:500" },
  //     { text: ">730", value: "gt:730" },
  //   ],
  //   filterMultiple: false,
  // },
];

const additionalColumns = [
  //////// PRO A & B surveys ///////////
  {
    title: "PRO A & PRO B Survey Completion Dates",
    children: [
      {
        title: "Fertility Preservation Questions",
        dataIndex: "Fertility Preservation Questions",
        key: "fertility_preservation_questions",
        width: 120,
      },
    ],
  },
  //////// PRO B only surveys /////////
  {
    title: "PRO B Survey Completion Dates",
    children: [
      {
        title: "Treatment Plan",
        dataIndex: "Treatment Plan",
        key: "treatment_plan",
        width: 120,
      },
    ],
  },
];

const sortString = (stringA, stringB) => {
  return ("" + stringA).localeCompare(stringB);
};
