import React, { useState } from "react";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Button, Radio, Row, Col, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as APIUtility from "../../Util/api";
const { Text } = Typography;

const Controls = (props) => {
  const [loading, setLoading] = useState(false);

  const downloadTableData = () => {
    setLoading(true);
    APIUtility.API.makeAPICall(APIUtility.GET_PATIENTS_LIST_CSV)
      .then((response) => response.text())
      .then((body) => {
        const file = new Blob([body], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = "all-participants.csv";
        downloadLink.click();
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Col xs={18} sm={12} md={12} lg={10} xl={10}>
        <Text>All Participants</Text>
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
        <Button onClick={downloadTableData} loading={loading}>
          <DownloadOutlined />
        </Button>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showAdditionalColumns: state.patientCompletionStatusTable.showAdditionalColumns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleShowAdditionalColumns: () => dispatch(actions.toggleShowAdditionalColumns()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
