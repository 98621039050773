export const parseOneYearData = (data) => {
  data.forEach((item) => {
    Object.entries(item["oneyear_completed_events"]).forEach(([key, value]) => {
      item[key] = value;
    });
  });
  return data;
};

/**
 * Method that returns a list of table columns
 * @param {boolean} anchorLeftColumnBool Boolean value to indicate whether to anchor some columns to the left.
 * @param {function} getColumnSearchProps Function used to search column in table.
 * @param {boolean} showMoreTableColumns Boolean value to indicate whether to show/hide some columns
 */
export const makeOneYearDataColumnsFunc = (anchorLeftColumnBool, getColumnSearchProps, showMoreTableColumns) => {
  if (showMoreTableColumns) {
    return [...fixedColumns(anchorLeftColumnBool, getColumnSearchProps), ...additionalColumns];
  } else {
    return fixedColumns(false, getColumnSearchProps);
  }
};

const fixedColumns = (anchorLeftColumnBool, getColumnSearchProps) => [
  {
    title: "Patient Id",
    dataIndex: "patient_id",
    key: "patient_id",
    width: 120,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.id, b.id),
    // ...getColumnSearchProps("id")
  },
  {
    title: "Site",
    dataIndex: "site_name",
    key: "site_name",
    width: 140,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.site, b.site),
    // ...getColumnSearchProps("site_name")
  },
  // {
  //   title: "Consent",
  //   dataIndex: "consent_descript",
  //   key: "consent_descript",
  //   width: 95,
  //   sorter: (a, b) => sortString(a.consent, b.consent),
  // },
  {
    title: "Enrollment",
    dataIndex: "enrollment_date",
    key: "enrollment_date",
    width: 120,
  },
  {
    title: "Group",
    dataIndex: "ruby_group",
    key: "ruby_group",
    width: 80,
    sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  },
  // {
  //   title: "Gift Status",
  //   dataIndex: "gift_card_status",
  //   key: "gift_card_status",
  //   width: 100,
  //   sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  // },
  {
    title: "Outstanding Surveys",
    dataIndex: "num_outstanding_surveys",
    key: "num_outstanding_surveys",
    width: 80,
    filters: [
      { text: ">=1", value: "gte:1" },
      { text: ">=2", value: "gte:2" },
      { text: ">=3", value: "gte:3" },
      { text: ">=5", value: "gte:5" },
      { text: ">=10", value: "gte:10" },
      { text: ">=15", value: "gte:15" },
      { text: ">=20", value: "gte:20" },
      { text: ">=25", value: "gte:25" },
    ],
    filterMultiple: false,
  },
  {
    title: "Pass 90 Day Window",
    dataIndex: "pass_reminder_window",
    key: "pass_reminder_window",
    width: 80,
    filters: [
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
    ],
    filterMultiple: false,
  },
  {
    title: "FFQ Complete",
    dataIndex: "ffq_complete",
    key: "ffq_complete",
    width: 80,
    filters: [
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
    ],
    filterMultiple: false,
  },
  // {
  //   title: "Days in Current Event",
  //   dataIndex: "days_in_cur_event",
  //   key: "days_in_cur_event",
  //   width: 80,
  //   filters: [
  //     { text: ">7", value: "gt:7" },
  //     { text: ">14", value: "gt:14" },
  //     { text: ">30", value: "gt:30" },
  //     { text: ">45", value: "gt:45" },
  //     { text: ">60", value: "gt:60" },
  //     { text: ">90", value: "gt:90" },
  //     { text: ">120", value: "gt:120" },
  //     { text: ">180", value: "gt:180" },
  //     { text: ">275", value: "gt:275" },
  //     { text: ">365", value: "gt:365" },
  //     { text: ">500", value: "gt:500" },
  //     { text: ">730", value: "gt:730" },
  //   ],
  //   filterMultiple: false,
  // },
];

export const additionalColumns = [
  /////// PRO A Only surveys /////////
  {
    title: "PRO A Survey Completion Dates",
    children: [
      {
        title: "Feelings of Depression",
        dataIndex: "Feelings of Depression",
        key: "feelings_of_depression",
        width: 120,
      },
      {
        title: "Trauma Checklist",
        dataIndex: "Trauma Checklist",
        key: "trauma_checklist",
        width: 120,
      },
      {
        title: "Pittsburgh Sleep Quality Index",
        dataIndex: "Pittsburgh Sleep Quality Index",
        key: "pittsburgh_sleep_quality_index",
        width: 120,
      },
      {
        title: "Marital Adjustment",
        dataIndex: "Marital Adjustment",
        key: "marital_adjustment",
        width: 120,
      },
      {
        title: "Reproductive Concerns After Cancer",
        dataIndex: "Reproductive Concerns After Cancer",
        key: "reproductive_concerns_after_cancer_rcac",
        width: 120,
      },
    ],
  },

  //////// PRO A & B surveys ///////////

  {
    title: "PRO A & PRO B Survey Completion Dates",
    children: [
      {
        title: "Annual Fertility Questionnaire",
        dataIndex: "Annual Fertility Questionnaire",
        key: "annual_fertility_questionnaire",
        width: 120,
      },
      {
        title: "Annual Genetic Testing",
        dataIndex: "Annual Genetic Testing",
        key: "annual_genetic_testing",
        width: 120,
      },
      {
        title: "Annual Health Check Up",
        dataIndex: "Annual Health Check Up",
        key: "annual_health_check_up",
        width: 120,
      },
      {
        title: "Breast-Q Follow-up",
        dataIndex: "Breast-Q Follow-up",
        key: "breastq_followup",
        width: 120,
      },
      {
        title: "General Anxiety",
        dataIndex: "General Anxiety",
        key: "general_anxiety",
        width: 120,
      },
      {
        title: "Psychological & CAM Therapies",
        dataIndex: "Use of Psychosocial & CAM Therapies",
        key: "use_of_psychological_cam_therapies",
        width: 120,
      },
      {
        title: "Illness Intrusiveness Rating Scale",
        dataIndex: "Illness Intrusiveness Rating Scale",
        key: "illness_intrusiveness_rating_scale",
        width: 120,
      },
      {
        title: "Fear of Cancer Recurrence",
        dataIndex: "Fear of Cancer Recurrence",
        key: "fear_of_cancer_recurrence",
        width: 120,
      },
      {
        title: "Work Situation",
        dataIndex: "Work Situation",
        key: "work_situation",
        width: 120,
      },
      {
        title: "Physical Activity & Sedentary Behaviour",
        dataIndex: "Physical Activity & Sedentary Behaviour",
        key: "physical_activity_sedentary_behaviour_questionnair",
        width: 120,
      },
      {
        title: "Food Frequency Questionnaire",
        dataIndex: "Food Frequency Questionnaire (FFQ)",
        key: "ffq",
        width: 120,
      },
      {
        title: "Other Studies",
        dataIndex: "Other Studies",
        key: "other_studies",
        width: 120,
      },
    ],
  },

  //////// PRO B only surveys /////////
  {
    title: "PRO B Survey Completion Dates",
    children: [
      {
        title: "Medical History Follow-up",
        dataIndex: "Medical History Follow-up",
        key: "medical_history_followup",
        width: 120,
      },
      {
        title: "Side Effect Expectations",
        dataIndex: "Side Effect Expectations",
        key: "side_effect_expectations",
        width: 120,
      },
      {
        title: "Breast Cancer Prevention",
        dataIndex: "Breast Cancer Prevention",
        key: "bcpt_symptom_checklist",
        width: 120,
      },
      {
        title: "Cancer Survivor Self-Efficacy Scale",
        dataIndex: "Breast Cancer Survivor Self-Efficacy Scale",
        key: "cancer_survivor_selfefficacy_scale",
        width: 120,
      },
      {
        title: "Brief Illness Perception",
        dataIndex: "Brief Illness Perception Questionnaire",
        key: "illness_beliefs",
        width: 120,
      },
      {
        title: "Beliefs About Cancer Treatment",
        dataIndex: "Beliefs About Cancer Treatment(s)",
        key: "beliefs_about_medicines",
        width: 120,
      },
      {
        title: "Adherence & Persistence Measures",
        dataIndex: "Adherence & Persistence Measures",
        key: "adherence_related_measures",
        width: 120,
      },
      {
        title: "Decision Regret Scale",
        dataIndex: "Decision Regret Scale",
        key: "decision_regret_scale",
        width: 120,
      },
    ],
  },
];

const sortString = (stringA, stringB) => {
  return ("" + stringA).localeCompare(stringB);
};
