import withRoot from "../../Containers/withRoot";
// --- Post bootstrap -----
import React from "react";

import AppAppBar from "../../Containers/views/AppAppBar";
import AppFooter from "../../Containers/views/AppFooter";

import Selector from "./Selector";
// import SurveyEventTable from "../../Containers/DataExplorer/SurveyEventTable";

const LandingPage = () => {
  return (
    <React.Fragment>
      <AppAppBar />
      <Selector />
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(LandingPage);
