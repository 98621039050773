export const makeDataColumnsFunc = (anchorLeftColumnBool, getColumnSearchProps, showMoreTableColumns) => {
  if (showMoreTableColumns) {
    return [...fixedColumns(anchorLeftColumnBool, getColumnSearchProps), ...additionalColumns];
  } else {
    return fixedColumns(false, getColumnSearchProps);
  }
};

const fixedColumns = (anchorLeftColumnBool, getColumnSearchProps) => [
  {
    title: "Patient Id",
    dataIndex: "patient_id",
    key: "patient_id",
    width: 120,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.id, b.id),
    // ...getColumnSearchProps("id")
  },
  {
    title: "Site",
    dataIndex: "site_name",
    key: "site_name",
    width: 140,
    fixed: anchorLeftColumnBool ? "left" : false,
    sorter: (a, b) => sortString(a.site, b.site),
    // ...getColumnSearchProps("site_name")
  },
  {
    title: "Consent Surveys",
    dataIndex: "consent_descript",
    key: "consent_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Consent Blood Samples",
    dataIndex: "consent_blood_descript",
    key: "consent_blood_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Consent Tissue Samples",
    dataIndex: "consent_tissue_descript",
    key: "consent_tissue_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Consent Future Studies",
    dataIndex: "consent_future_studies_descript",
    key: "consent_future_studies_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Consent Future Genetics",
    dataIndex: "consent_future_genetics_descript",
    key: "consent_future_genetics_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Consent Brocap",
    dataIndex: "consent_brocap_descript",
    key: "consent_brocap_descript",
    width: 95,
    sorter: (a, b) => sortString(a.consent, b.consent),
  },
  {
    title: "Group",
    dataIndex: "ruby_group",
    key: "ruby_group",
    width: 80,
    sorter: (a, b) => sortString(a.ruby_pro_assignment, b.ruby_pro_assignment),
  },
  {
    title: "Enrollment Date",
    dataIndex: "enrollment_date",
    key: "enrollment_date",
    width: 120,
  },
  {
    title: "Brocap Consent Date",
    dataIndex: "brocap_consent_date",
    key: "brocap_consent_date",
    width: 120,
  },
  {
    title: "Baseline Status",
    dataIndex: "baseline_status",
    key: "baseline_status",
    width: 100,
    sorter: (a, b) => sortString(a.id, b.id),
  },
  {
    title: "3-Month Status",
    dataIndex: "threemonth_status",
    key: "threemonth_status",
    width: 100,
    sorter: (a, b) => sortString(a.id, b.id),
  },
  {
    title: "1-Year Status",
    dataIndex: "oneyear_status",
    key: "oneyear_status",
    width: 100,
    sorter: (a, b) => sortString(a.id, b.id),
  },
  {
    title: "2-Year Status",
    dataIndex: "twoyear_status",
    key: "twoyear_status",
    width: 100,
    sorter: (a, b) => sortString(a.id, b.id),
  },
  {
    title: "3-Year Status",
    dataIndex: "threeyear_status",
    key: "threeyear_status",
    width: 100,
    sorter: (a, b) => sortString(a.id, b.id),
  },
];

const additionalColumns = [];

const sortString = (stringA, stringB) => {
  return ("" + stringA).localeCompare(stringB);
};
