import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../../Components/Typography/Typography";

// function Copyright() {
//   return (
//     <React.Fragment>
//       {"© "}
//       <Link color="inherit" href="/">
//         RUBY
//       </Link>{" "}
//       {new Date().getFullYear()}
//     </React.Fragment>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    textDecoration: "none",
  },

  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} justify="center">
          <Grid item>
            <Typography variant="overline">
              <Link
                color="inherit"
                href="https://cumming.ucalgary.ca/centres/centre-health-informatics"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Centre for Health Informatics
              </Link>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="overline">
              <Link
                color="inherit"
                href="https://cumming.ucalgary.ca"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Cumming School of Medicine
              </Link>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="overline">
              <Link color="inherit" href="https://www.ucalgary.ca" rel="noopener noreferrer nofollow" target="_blank">
                University of Calgary
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
