import React from "react";
import * as d3 from "d3";
import { sankey, sankeyLinkHorizontal, sankeyLeft } from "d3-sankey";
import chroma from "chroma-js";

const SankeyNode = ({ name, x0, x1, y0, y1, color, tooltip, svg }) => {
  const nodeH = y1 - y0;
  const nodeW = x1 - x0;
  let textX = x0 + 0.5 * nodeW;
  let textY = y1 - nodeH / 2 + 3;
  let label = name;

  //for nodes that are too short to fit text label, take only first character
  if (nodeH < 150) {
    label = name.slice(0, 6) + "..";
  }
  if (nodeH < 100) {
    label = name.slice(0, 4) + "..";
  }

  if (nodeH < 80) {
    label = name[0] + "..";
  }

  if (nodeH < 40) {
    label = name[0];
  }

  if (nodeH < 20) {
    label = "";
  }

  return (
    <g>
      <rect
        x={x0}
        y={y0}
        width={nodeW}
        height={nodeH}
        fill={color}
        onMouseMove={(event) => {
          const [x, y, textAnchor] = calculateTooltipPosition(svg, event);
          tooltip.attr("x", x).attr("y", y).attr("text-anchor", textAnchor).text(name);
        }}
        onMouseEnter={() => {
          tooltip.attr("opacity", 1);
        }}
        onMouseLeave={() => {
          tooltip.attr("opacity", 0);
        }}
      />
      <text
        x={textX}
        y={textY}
        textAnchor="middle"
        fontSize="20"
        style={{ writingMode: "vertical-rl" }}
        onMouseMove={(event) => {
          const [x, y, textAnchor] = calculateTooltipPosition(svg, event);
          tooltip.attr("x", x).attr("y", y).attr("text-anchor", textAnchor).text(name);
        }}
        onMouseEnter={() => {
          tooltip.attr("opacity", 1);
        }}
        onMouseLeave={() => {
          tooltip.attr("opacity", 0);
        }}
      >
        {label}
      </text>
    </g>
  );
};

const SankeyLink = ({ link, color, tooltip, svg }) => (
  <path
    d={sankeyLinkHorizontal()(link)}
    style={{
      fill: "none",
      strokeOpacity: ".5",
      stroke: color,
      strokeWidth: Math.max(1, link.width),
    }}
    onMouseMove={(event) => {
      const [x, y, textAnchor] = calculateTooltipPosition(svg, event);
      tooltip
        .attr("x", x)
        .attr("y", y)
        .attr("text-anchor", textAnchor)
        .text(`${link.source.name} -> ${link.target.name}: ${link.value} participants`);
    }}
    onMouseEnter={() => {
      tooltip.attr("opacity", 1);
    }}
    onMouseLeave={() => {
      tooltip.attr("opacity", 0);
    }}
  />
);

const calculateTooltipPosition = (svg, event) => {
  const frame = svg.getBoundingClientRect();
  const marginRight = 200;
  const marginTop = 20;
  const cursorX = event.pageX - frame.left;
  const cursorY = event.pageY - frame.top;
  let x, y, textAnchor;
  if (cursorY < marginTop) {
    y = cursorY + 32;
  } else {
    y = cursorY - 8;
  }

  if (frame.right - event.pageX < marginRight) {
    x = cursorX - 5;
    textAnchor = "end";
  } else {
    x = cursorX;
    textAnchor = "start";
  }
  return [x, y, textAnchor];
};

/**
 * svg components for sankey diagram built on d3-sankey
 */
const SankySVG = ({ data, width, height, svg }) => {
  const { nodes, links } = sankey()
    .nodeWidth(35)
    .nodePadding(10)
    .nodeAlign(sankeyLeft)
    .extent([
      [1, 1],
      [width - 1, height - 5],
    ])(data);

  const Tooltip = d3
    .select(svg)
    .append("text")
    .attr("class", "sankey-tooltip")
    .attr("font-size", 14)
    .attr("font-color", "red")
    .attr("opacity", 0);

  const color = chroma.scale("Set3").classes(nodes.length);

  const colorScale = d3.scaleLinear().domain([0, nodes.length]).range([0, 1]);

  if ((!width > 0) | (!height > 0)) return null;

  return (
    <g style={{ mixBlendMode: "multiply" }}>
      {nodes.map((node, i) => (
        <SankeyNode {...node} color={color(colorScale(i)).hex()} key={node.name} tooltip={Tooltip} svg={svg} />
      ))}
      {links.map((link, i) => (
        <SankeyLink
          link={link}
          color={color(colorScale(link.source.index)).hex()}
          key={`${link.source.name}-${link.target.name}`}
          tooltip={Tooltip}
          svg={svg}
        />
      ))}
    </g>
  );
};

export default SankySVG;
