import React from "react";
import * as actions from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Radio, Col, Typography, Popover, Button, Space } from "antd";
import { PieChartOutlined } from "@ant-design/icons";
import SiteSelector from "./SiteSelector";

const { Text } = Typography;

const Controls = (props) => {
  const renderShowMoreOrFewButton = () => {
    if (props.showMorePieCharts) {
      return "Fewer";
    } else {
      return "More";
    }
  };

  const sizeMenu = () => {
    return (
      <Space>
        {/* <Radio.Group>
          <Radio.Button onClick={props.increasePieSize}>Bigger</Radio.Button>
          <Radio.Button onClick={props.reducePieSize}>Smaller</Radio.Button>
        </Radio.Group> */}
        <Radio.Group>
          <Radio.Button onClick={props.toggleShowMoreCharts}>{renderShowMoreOrFewButton()}</Radio.Button>
        </Radio.Group>
      </Space>
    );
  };

  return (
    <React.Fragment>
      <Col span={16} style={{ padding: 0 }}>
        <Text>Study Completion Status</Text>
      </Col>
      <Col offset={2} span={4}>
        <Space>
          <Popover content={sizeMenu()}>
            <Button icon={<PieChartOutlined />}></Button>
          </Popover>
          <SiteSelector />
        </Space>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    pieRadius: state.studyCompletionStatus.pieRadius,
    showMorePieCharts: state.studyCompletionStatus.showMorePieCharts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    increasePieSize: () => dispatch(actions.increasePieChartSizeCompStatus()),
    reducePieSize: () => dispatch(actions.reducePieChartSizeCompStatus()),
    toggleShowMoreCharts: () => dispatch(actions.toggleShowMorePieChartCompStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
